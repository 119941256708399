<div class="section-full p-t120 p-b90  site-bg-white">

    <div class="container">


        <div class="section-content team_details_area">
            <div class="row justify-content-center">
                <div class="col-lg-5 col-md-6 m-b30">
                    <div class="wt-team-1">

                        <div class="wt-media">
                            <img src="{{profile.image}}" alt="">
                        </div>

                    </div>
                </div>

                <div class="col-lg-7 col-md-6 m-b30 team-box-single site-bg-gray-light">

                    <div class="team-detail">
                        <span class="team-position">{{profile.expertise}}</span>
                        <h4 class="m-t0 team-name"><a routerLink="/our-team-detail">{{profile.name}}</a></h4>

                        <ul class="team-detail-list">
                            <li><span class="team-list-title">Contact Number:</span><span>{{profile.contact}}</span></li>
                            <li><span class="team-list-title">Email:</span><span>{{profile.email}}</span></li>
                            <li><span class="team-list-title">Joing Date:</span><span>{{profile.joining}}</span></li>
                            <li><span class="team-list-title">Services & Support:</span><span>{{profile.services}}</span></li>
                            <li><span class="team-list-title">Address:</span><span>{{profile.address}}</span></li>
                            <li><span class="team-list-title">Experience:</span><span>{{profile.experience}}</span></li>
                        </ul>

                        <div class="team-detail-social">
                            <ul class="team-social-bar">
                                <li><a href="https://www.facebook.com"><i class="fa fa-facebook"></i></a></li>
                                <li><a href="https://twitter.com"><i class="fa fa-twitter"></i></a></li>
                                <li><a href="https://in.linkedin.com"><i class="fa fa-linkedin"></i></a></li>
                                <li><a href="https://in.pinterest.com"><i class="fa fa-pinterest"></i></a></li>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</div>