<div class="section-full p-t120 p-b90 site-bg-gray-light">

    <div class="container">
        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer">
            <div class="wt-small-separator site-text-primary">
                <div class="sep-leaf-left"></div>
                <div>Tell Us Now</div>

            </div>
            <h2>If you have any Question in your mind</h2>
        </div>
        <!-- TITLE END-->
        <div class="section-content">
            <div class="row justify-content-center d-flex">

                <div class="col-lg-7 col-md-12 m-b30">
                    <div class="faq-help-form-outer">


                        <div class="faq-help-form">
                            <div class="form-group">
                                <input type="text" placeholder="Enter your name" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="email" placeholder="Enter your email" class="form-control">
                            </div>
                            <div class="form-group">
                                <textarea class="form-control" placeholder="Enter your message"></textarea>
                            </div>
                            <div>
                                <button type="submit" class="site-button sb-bdr-dark">Submit Now</button>
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    </div>
</div>