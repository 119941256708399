<div class="related_posts m-b30">
    <h3 class="wt-title">Realated Post</h3>
    <div class="site-bg-gray-light p-a30">
        <div class="owl-carousel related-posts-carousel  owl-btn-bottom-center ">
            <!-- COLUMNS 1 -->
            <div class="item" *ngFor="let post of data">

                <div class="blog-post blog-post-4-outer">
                    <div class="wt-post-media wt-img-effect zoom-slow">
                        <a routerLink="{{post.linkToReadMore}}"><img src="{{post.image}}"
                                alt=""></a>
                    </div>
                    <div class="wt-post-info">
                        <div class="wt-post-meta ">
                            <ul>
                                <li class="post-date"><span>{{post.day}}</span>{{post.month}}</li>
                                <li class="post-category">{{post.field}}</li>
                            </ul>
                        </div>
                        <div class="wt-post-title ">
                            <h4 class="post-title"><a routerLink="{{post.linkToReadMore}}">{{post.title}}</a></h4>
                        </div>

                        <div class="wt-post-readmore ">
                            <a routerLink="{{post.linkToReadMore}}"
                                class="site-button-link black">Read More</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>