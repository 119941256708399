<div class="widget widget_team p-a30 bg-white">
    <div class="text-left m-b30">
        <h4 class="widget-title">{{data.title}}</h4>
    </div>
    <div class="widget-media m-b30">
        <img src="{{data.image}}">
    </div>
    <div class="team-detail  text-center">
        <h4 class="m-t0">{{data.name}}</h4>
        <p>
            {{data.description}}
        </p>
        <div class="team-social-center">
            <ul>
                <li><a href="https://www.facebook.com"><i class="fa fa-facebook"></i></a>
                </li>
                <li><a href="https://twitter.com"><i class="fa fa-twitter"></i></a></li>
                <li><a href="https://in.linkedin.com"><i class="fa fa-linkedin"></i></a>
                </li>
                <li><a href="https://in.pinterest.com"><i class="fa fa-pinterest"></i></a>
                </li>
            </ul>
        </div>
    </div>

</div>