<div class="page-wraper">

    <!-- HEADER START -->
    <app-header2></app-header2>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- SLIDER START -->
        <app-slider2></app-slider2>
        <!-- SLIDER END -->

        <!-- ABOUT ONE SECTION START -->
        <app-about-section2 [data]="aboutSection"></app-about-section2>
        <!-- ABOUT ONE SECTION END -->

        <!-- SERVICES SECTION START -->
        <div class="section-full p-t120  p-b90"
            style="background-image:url(assets/images/background/map-bg-dark2.png);">

            <div class="container">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer">
                    <div class="wt-small-separator site-text-primary">
                        <div class="sep-leaf-left"></div>
                        <div>Our Services</div>
                    </div>
                    <h2 class="wt-title">We Provide Best Services</h2>
                </div>
                <!-- TITLE END-->
                <div class="s-section">
                    <div class="row">

                        <!-- COLUMNS -->
                        <app-box-service1 class="col-lg-4 col-md-6 m-b30" *ngFor="let service of services"
                            [data]="service"></app-box-service1>

                    </div>

                    <div class="text-center">
                        <a routerLink="/services" class="site-button sb-bdr-dark">Explore All Services</a>
                    </div>

                </div>

            </div>



        </div>
        <!-- SERVICES SECTION END -->

        <!-- SOME FACTS START -->
        <app-facts2 [data]="factsSection"></app-facts2>
        <!-- SOME FACTS END -->

        <!-- OUR TEAM START -->
        <div class="section-full p-t120 p-b90">
            <app-team3 [data]="teamSection3" [width]="3"></app-team3>
        </div>
        <!-- OUR TEAM SECTION END -->

        <!-- PROJECT SECTION START -->
        <div class="section-full  p-t120 p-b90 site-bg-gray-light">
            <div class="container-fluid">
                <!-- TITLE START-->
                <div class="section-head center wt-small-separator-outer">
                    <div class="wt-small-separator site-text-primary">
                        <div class="sep-leaf-left"></div>
                        <div>Our recent work</div>
                    </div>
                    <h2 class="wt-title">Recently Completed Projects</h2>
                </div>
                <!-- TITLE END-->

                <div class="section-content">
                    <div class="owl-carousel project-slider1  project-box-style1-outer m-b30">

                        <!-- COLUMNS 1 -->
                        <app-project-box1 class="item" *ngFor="let project of recentProjects" [project]="project">
                        </app-project-box1>

                    </div>
                </div>
            </div>

        </div>
        <!-- PROJECT CHOOSE SECTION END -->

        <!-- TESTIMONIAL SECTION START -->
        <div class="section-full  p-t120 p-b90 overlay-wraper testimonial-2-outer site-bg-black bg-cover"
            style="background-image:url(assets/images/background/bg3.jpg);">
            <div class="overlay-main site-bg-black opacity-08"></div>
            <app-testimonial2 [data]="testimonialsSection" [darkBack]="1"></app-testimonial2>
        </div>
        <!-- TESTIMONIAL SECTION END -->

        <!-- OUR BLOG START -->
        <app-updates2 [data]="updatesSection"></app-updates2>
        <!-- OUR BLOG END -->

        <!-- HELP SECTION START -->
        <app-features1 class="section-full p-t0 p-b0" [data]="features" [padding]="0"></app-features1>
        <!-- HELP SECTION End -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer2></app-footer2>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>