import { Component, OnInit } from '@angular/core';
@Component
  (
    {
      selector: 'app-about1',
      templateUrl: './about1.component.html',
      styleUrls: ['./about1.component.css']
    }
  )
export class About1Component implements OnInit {
  constructor() {
  }
  ngOnInit(): void {
  }
  aboutSection = [
    {
      sectionImage: "assets/images/pexels-pepyto-527590145-16416032.jpg",
      title: "About Us",
      subtitle: "Nalplast operates one of the most advanced factories in KSA.",
      yearsOfExperience: "25+",
      description: "Nalplast established to provide constant innovations in plumbing, irrigation, sewerage, electrical and telecommunication technologies to meet the vision of the kingdom of Saudi Arabia 2030 especially for the increasing infrastructure demands. Nalplast constantly strives to pave the way to an advanced piping systems that will no doubt assist and serve the developments of the new vision 2030.",
      features: [
        {
          icon: "flaticon-briefing",
          title: "Mission",
          description: "Our mission is to create a revolution in the plastic piping industry by introducing innovative solutions that will serve and benefit customers and the society."
        },
        {
          icon: "flaticon-reliability",
          title: "Vision",
          description: "To become an acknowledged leader in the Saudi Plastic Piping Industry, exceeding customers’ expectations and boosting stakeholders returns."
        }
      ]
    }
  ];
  aboutUsSection =
    [
      {
        sectionImage: "assets/images/pexels-emiliano-fanti-2378708.jpg",
        title: "About Us",
        subtitle: "Nalplast operates one of the most advanced factories in KSA, offering a wide range of products to serve various industries.",
        yearsOfSuccess: "25",
        description: `Nalplast established to provide constant innovations in plumbing, irrigation, sewerage, electrical and telecommunication technologies to meet the vision of the kingdom of Saudi Arabia 2030 especially for the increasing infrastructure demands. Nalplast constantly strives to pave the way to an advanced piping systems that will no doubt assist and serve the developments of the new vision 2030.`,
        description2: 'Nalplast Is a well-structured organization equipped with the most advanced and reliable machinery that creates a zero-defect manufacturing process. our fully computerized material mixing facilities can provide the constant homogeneous material for production. Through its closed material supply system Nalplast also provide a contamination free material for production lines.',
        description3: 'Our piping production lines incorporates the most advance PVC three layer and foam core production lines which is first to be introduced in the Kingdom of Saudi Arabia, in addition the DWC polyethylene lines will put Nalplast as one of the top three manufacturers of DWC in the Kingdom. ',
        description4: 'injection moulding machines can produce a verity of products that complement the range of produced pipes. The high-quality moulds are setting Nalplast fittings as one of the qualities in the market.',
        description5: 'In fact, no product can be best unless a well-controlled processing parameters are being set and quality team is taking care of ensuring a consistent quality output and this is what makes Nalplast advanced.',
        features:
          [
            {
              icon: "flaticon-worker",
              title: "Top Class",
              description: ""
            },
            {
              icon: "flaticon-reliability",
              title: "State-Of-The-Art Setup",
              description: ""
            },
            {
              icon: "flaticon-reliability",
              title: "Reliable Brand",
              description: ""
            },
          ]
      }
    ];
  teamSection1 = [
    {
      title: "Team of Professionals",
      subtitle: "Expert Contractors",
      videoUrl: "https://www.youtube.com/watch?v=c1XNqw2gSbU",
      team: [
        {
          image: "assets/images/team/pic1.jpg",
          expertise: "Mechanical Engineer",
          name: "William Dixon"
        },
        {
          image: "assets/images/team/pic2.jpg",
          expertise: "Industry Expert",
          name: "Gabriela Flores"
        },
        {
          image: "assets/images/team/pic3.jpg",
          expertise: "Mechanical Engineer",
          name: "Juliana Bryant"
        }
      ]
    }
  ];

  factsSection =
    [
      {
        subtitle: "We believe in building long lasting our business relationships.",
        contact: "+966 11 4655880",
        email: "info@gmail.com",
        videoThumbnail: "assets/images/background/bg-2.jpg",
        videoUrl: "https://www.youtube.com/watch?v=c1XNqw2gSbU",
        facts:
          [
            {
              icon: "flaticon-worker",
              count: "808",
              title: "Contractors"
            },
            {
              icon: "flaticon-briefing",
              count: "950",
              title: "Projects Done!"
            },
            {
              icon: "flaticon-factory",
              count: "560",
              title: "Industries Served"
            },
            {
              icon: "flaticon-reliability",
              count: "996",
              title: "Trusted By"
            }
          ]
      }
    ];
  recentProjects = [
    {
      title: "Energy",
      subtitle: `With a land area of 20,000 square meters, the NALPLAST factory was established in 2023 as a revival of the (ASPPCO 2015-2020). It stands as one of the "newest state of the art factories" in the Kingdom of Saudi Arabia, aligning with the Smart Industry Readiness Index "SIRI" program as outlined in Vision 2030. At NALPLAST, we are dedicated to showcasing our latest advancements in plastic pipes and fittings. To achieve this, we have meticulously integrated state-of-the-art machinery, positioning the factory uniquely in the market.`,
      image: "assets/images/project/1.jpg",
      author: "Kinan",
      link: ""
    },
    {
      title: "Industry",
      subtitle: "Simple Decription",
      image: "assets/images/project/2.jpg",
      author: "Kinan",
      link: "/project-single"
    },
    {
      title: "Construction",
      subtitle: "Simple Decription",
      image: "assets/images/project/3.jpg",
      author: "Kinan",
      link: "/project-single"
    },
    {
      title: "Oil & Gas",
      subtitle: "Simple Decription",
      image: "assets/images/project/4.jpg",
      author: "Kinan",
      link: "/project-single"
    }
  ];
  buildFutureSection =
    [
      {
        sectionImage: "assets/images/background/bg12.jpg",
        title: "Build Future",
        subtitle: "We provide the highest quality of work that meets your expectations",
        fields:
          [
            {
              field: "Industry",
              qualityPercentage: "80"
            },
            {
              field: "Construction",
              qualityPercentage: "90"
            },
            {
              field: "Factory",
              qualityPercentage: "95"
            }
          ]
      }
    ];
  testimonialsSection =
    [
      {
        title: "Testimonial",
        subtitle: "Happy Client Says About Us",
        testimonials:
          [
            {
              image: "assets/images/testimonials/pic1.jpg",
              name: "Mike Hardson",
              designation: "Director",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            },
            {
              image: "assets/images/testimonials/pic2.jpg",
              name: "Edword Howells",
              designation: "Founder & CEO",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            },
            {
              image: "assets/images/testimonials/pic3.jpg",
              name: "David Lee",
              designation: "Contractor",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            },
            {
              image: "assets/images/testimonials/pic1.jpg",
              name: "Mike Hardson",
              designation: "Director",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            },
            {
              image: "assets/images/testimonials/pic2.jpg",
              name: "Edword Howells",
              designation: "Founder & CEO",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            },
            {
              image: "assets/images/testimonials/pic3.jpg",
              name: "David Lee",
              designation: "Contractor",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            },
            {
              image: "assets/images/testimonials/pic1.jpg",
              name: "Mike Hardson",
              designation: "Director",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            },
            {
              image: "assets/images/testimonials/pic2.jpg",
              name: "Edword Howells",
              designation: "Founder & CEO",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            },
            {
              image: "assets/images/testimonials/pic3.jpg",
              name: "David Lee",
              designation: "Contractor",
              quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
            }
          ]
      }
    ];
  featuresSection =
    [
      {
        features:
          [
            {
              background: "bg-white",
              textColor: "white",
              icon: "flaticon-briefing",
              title: "Our Mission",
              description: "Our mission is to create a revolution in the plastic piping industry by introducing innovative solutions that will serve and benefit customers and the society. "
            },
            {
              background: "bg-white",
              textColor: "white",
              icon: "flaticon-lightbulb",
              title: "Our Vision",
              description: "To become an acknowledged leader in the Saudi Plastic Piping Industry, exceeding customers’ expectations and boosting stakeholders returns."
            },
            {
              background: "bg-white",
              textColor: "white",
              icon: "flaticon-worker",
              title: "Our Values",
              description: "Consistently increasing distributor base to make sure the customer proximity and readiness to address their requirements inside and outside Saudi Arabia."
            }
          ]
      }
    ];
}