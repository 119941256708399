<div class="section-full  p-t120 p-b90 site-bg-gray-light">
    <div class="section-content">
        <div class="container">
            <div class="owl-carousel owl-carousel-filter  owl-btn-bottom-center project-new-1-outer m-b30">
                <!-- COLUMNS 1 -->

                <app-project-box1 class="item" *ngFor="let project of projects" [project]="project"></app-project-box1>

            </div>
        </div>
    </div>

</div>