<!-- TESTIMONIAL SECTION START -->
<div class="section-full  p-t120 p-b90 testimonial-1-outer bg-white">
    <ng-template ngFor let-data [ngForOf]="data">
        <div class="container">
            <div class="section-content">
                <div class="row">
                    <div class="col-xl-6 col-lg-12 col-md-12">
                        <!-- TITLE START-->
                        <div class="section-head left wt-small-separator-outer">
                            <div class="wt-small-separator site-text-primary">
                                <div class="sep-leaf-left"></div>
                                <div>{{data.title}}</div>
                            </div>
                            <h2 class="wt-title">{{data.subtitle}}</h2>
                        </div>
                        <!-- TITLE END-->

                    </div>

                </div>

                <div class="testimonial-title-large mb-3">
                    <!-- <span>Testimonials</span> -->
                </div>
                <div class="testimonial-1-content owl-carousel">
                    <!--blocks-->
                    <div class="item" *ngFor="let item of data.testimonials">
                        <div class="testimonial-1 bg-white">
                            <div class="testimonial-content">
                                <div class="testimonial-detail clearfix">
                                    <div class="testimonial-pic-block">
                                        <div class="testimonial-pic">
                                            <img src="{{item.image}}" alt="">
                                        </div>
                                    </div>
                                    <div class="testimonial-info">
                                        <span class="testimonial-name">{{item.name}}</span>
                                        <span class="testimonial-position">{{item.designation}}</span>
                                    </div>
                                </div>

                                <div class="testimonial-text">
                                    <p>
                                        {{item.quote}}
                                    </p>
                                    <i class="flaticon-quote-1"></i>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </ng-template>
</div>
<!-- TESTIMONIAL SECTION END -->