<div class="page-wraper">

    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->


    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-page-banner title="About Us 2" subtitle="About 2"></app-page-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- ABOUT ONE SECTION START -->

        <!-- ABOUT ONE SECTION END -->

        <!-- OUR TEAM START -->
        <app-team2 [data]="teamSection"></app-team2>
        <!-- OUR TEAM SECTION END -->

        <!-- OUR BLOG START -->
        <app-updates2 [data]="updatesSection"></app-updates2>
        <!-- OUR BLOG END -->

        <!-- VISION SECTION START -->
        <app-features2 [data]="featuresSection"></app-features2>
        <!-- VISION SECTION END -->

    </div>
    <!-- CONTENT END -->

    <app-footer1></app-footer1>

</div>

<app-loader></app-loader>