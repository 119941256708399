import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home1',
  templateUrl: './home1.component.html',
  styleUrls: ['./home1.component.css']
})
export class Home1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  aboutUsSection = [
    {
      sectionImage: "assets/images/intro-pic.jpg",
      title: "About Us",
      subtitle: "The Best Solution",
      yearsOfSuccess: "9",
      description: `
     NalPlast was established in Riyadh, KSA as the new facelift of Asppco plastic pipes
and fittings factory. It is a part of BinZafrah Holding and its first industrial unit that
was established in 2014 and initially started its activities in 2015.
NalPlast is considered as one of the top promising quality manufacturers in the
field of PVC/cPVC/HDPE Pipes and fittings with a wide range of products.
Our aim is to be one of the top leading manufacturing companies, recognized for
its top quality, innovative, cost-effective, and sustainable products.
      
      `,
      features: [
        {
          title: "Best Quality Services",
          description: "Train with the best experts in body building field."
        },
        {
          title: "Lean Machines",
          description: "Our personal trainers will help you find a perfect workout."
        }
      ]
    }
  ];

  factsSection = [
    {
      title: "Some Facts",
      subtitle: "Expert Workers Are Giving Best Services To Build Your Dream Factory.",
      videoThumbnail: "assets/images/background/bg-2.jpg",
      videoUrl: "https://www.youtube.com/watch?v=c1XNqw2gSbU",
      facts: [
        {
          icon: "flaticon-worker",
          count: "808",
          title: "Contractors"
        },
        {
          icon: "flaticon-briefing",
          count: "950",
          title: "Projects Done!"
        },
        {
          icon: "flaticon-factory",
          count: "560",
          title: "Industries Served"
        },
        {
          icon: "flaticon-reliability",
          count: "996",
          title: "Trusted By"
        }
      ]
    }
  ];

  teamSection1 = [
    {
      title: "Team of Professionals",
      subtitle: "Expert Contractors",
      videoUrl: "https://www.youtube.com/watch?v=c1XNqw2gSbU",
      team: [
        {
          image: "assets/images/team/pic1.jpg",
          expertise: "Mechanical Engineer",
          name: "William Dixon"
        },
        {
          image: "assets/images/team/pic2.jpg",
          expertise: "Industry Expert",
          name: "Gabriela Flores"
        },
        {
          image: "assets/images/team/pic3.jpg",
          expertise: "Mechanical Engineer",
          name: "Juliana Bryant"
        }
      ]
    }
  ];

  servicesSection = [
    {
      title: "Services",
      subtitle: "",
      description: "",
      services: [
        {
          icon: "flaticon-helmet",
          title: "HDPE Pipes and Fittings",
          description: "",
          linkToReadMore: "/"
        },
        {
          icon: "flaticon-physics",
          title: "PPR Pipes and Fittings",
          description: "",
          linkToReadMore: "/"
        },
        {
          icon: "flaticon-work-time",
          title: "Cpvc Pipes and Fittings",
          description: "We produce cPVC from size ½” to 3”",
          linkToReadMore: "/l"
        },
        {
          icon: "flaticon-helmet",
          title: "PVC Pipes and Fittings",
          description: "We produce PVC from size ½” to 16” or 20mm to 400mm.",
          linkToReadMore: "/service-detail"
        }

      ]
    }
  ];

  buildFutureSection = [
    {
      sectionImage: "assets/images/background/bg12.jpg",
      title: "Build Future",
      subtitle: `
                  Our company aspires to be a leading supplier of high-quality
                  products to a wide range of sectors. We intend to revolutionize
                  the manufacturing industry by introducing an innovative product
                  that will offer the consumer a quality product. With a strong
                  commitment to innovation, sustainability &amp; customer satisfaction,
                  we strive to offer reliable, durable &amp; cost-effective solutions to
                  meet market demands. By incorporating cutting-edge
                  technologies, industry expertise, and efficient methods, we hope
                  to establish a significant presence in both domestic and
                  international markets.
                `,
      fields: [

      ]
    }
  ];

  testimonialsSection = [
    {
      title: "Testimonial",
      subtitle: "Happy Client Says About Us",
      testimonials: [
        {
          image: "assets/images/testimonials/pic1.jpg",
          name: "Mike Hardson",
          designation: "Director",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        },
        {
          image: "assets/images/testimonials/pic2.jpg",
          name: "Edword Howells",
          designation: "Founder & CEO",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        },
        {
          image: "assets/images/testimonials/pic3.jpg",
          name: "David Lee",
          designation: "Contractor",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        },
        {
          image: "assets/images/testimonials/pic1.jpg",
          name: "Mike Hardson",
          designation: "Director",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        },
        {
          image: "assets/images/testimonials/pic2.jpg",
          name: "Edword Howells",
          designation: "Founder & CEO",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        },
        {
          image: "assets/images/testimonials/pic3.jpg",
          name: "David Lee",
          designation: "Contractor",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        },
        {
          image: "assets/images/testimonials/pic1.jpg",
          name: "Mike Hardson",
          designation: "Director",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        },
        {
          image: "assets/images/testimonials/pic2.jpg",
          name: "Edword Howells",
          designation: "Founder & CEO",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        },
        {
          image: "assets/images/testimonials/pic3.jpg",
          name: "David Lee",
          designation: "Contractor",
          quote: "They are the expertly trained team members who take extra step and go the extra mile, to fulfill promise to deliver dynamic solutions to our customers to fit"
        }
      ]
    }
  ];

  updatesSection = [
    {
      title: "News And Updates",
      subtitle: "Let's Checkout Our All Current News.",
      updates: [
        {
          image: "assets/images/blog/latest/bg1.png",
          day: "13",
          month: "July",
          field: "Factory",
          title: "Cargo flow through better supply chain visibility.",
          description: "The trade war currently ensuing between the US and several nations around the globe, most fiercely with China,",
          linkToReadMore: "/blog-single"
        },
        {
          image: "assets/images/blog/latest/bg2.png",
          day: "18",
          month: "July",
          field: "Industry",
          title: "The future of factories in the coming years",
          description: "The trade war currently ensuing between the US and several nations around the globe, most fiercely with China,",
          linkToReadMore: "/blog-single"
        },
        {
          image: "assets/images/blog/latest/bg3.png",
          day: "19",
          month: "July",
          field: "Energy",
          title: "The factors that countries create are energy",
          description: "The trade war currently ensuing between the US and several nations around the globe, most fiercely with China,",
          linkToReadMore: "/blog-single"
        }
      ]
    }
  ];

  clientsSection = [
    {
      title: "Our Clients",
      subtitle: "A Few of Our Clients",
      clients: [
        {
          image: "assets/images/client-logo/w1.webp"
        },
        {
          image: "assets/images/client-logo/w2.png"
        },

      ],
      features: [
        // {
        //   background: "site-bg-primary",
        //   textColor: "white",
        //   icon: "flaticon-lightbulb",
        //   title: "Creative Ideas",
        //   subtitle: "Train with the best experts in bodybuilding field."
        // },
        // {
        //   background: "site-bg-gray",
        //   textColor: "",
        //   icon: "flaticon-customer-service",
        //   title: "24/7 Support",
        //   subtitle: "Train with the best experts in bodybuilding field"
        // },
        // {
        //   background: "site-bg-black",
        //   textColor: "white",
        //   icon: "flaticon-antivirus",
        //   title: "Super Safety",
        //   subtitle: "Train with the best experts in bodybuilding field."
        // }
      ]
    }
  ];
}
