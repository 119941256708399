<div class="slider-outer">

    <!-- START REVOLUTION SLIDER 5.4.1 -->


    <div id="welcome_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="typewriter-effect"
        data-source="gallery">
        <div id="webmax-three" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.3.1">
            <ul>
                <!-- SLIDE 1 -->
                <li data-index="rs-901" data-transition="slidingoverlayhorizontal" data-slotamount="default"
                    data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default"
                    data-masterspeed="default" data-thumb="assets/images/main-slider/slider1//slide1.jpg"
                    data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-description="">

                    <!-- MAIN IMAGE -->

                    <img src="assets/images/main-slider/slider3/slide1.jpg" alt="" data-bgposition="center bottom"
                        data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina />


                    <!-- LAYER NR. 0 [ for overlay ] -->
                    <div class="tp-caption tp-shape tp-shapewrapper " id="slide-901-layer-0"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                        data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                        {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                        ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 1;background-color:rgba(0, 0, 0, 0.3);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                    </div>

                    <!-- LAYER NR. 2 [ for title ] -->
                    <div class="tp-caption   tp-resizeme" id="slide-901-layer-2"
                        data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
                        data-y="['middle','middle','top','top']" data-voffset="['-120','-120','190','240']"
                        data-fontsize="['20','20','20','14']" data-lineheight="['20','20','20','14']"
                        data-width="['600','500','700','400']" data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on" data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-textAlign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 2; 
                            white-space: normal; 
                            font-weight: 600;
                            color:#fff;
                            border-width:0px; font-family: 'Poppins', sans-serif;">
                        <div class="site-text-white">nlp Option 3ing</div>
                    </div>

                    <!-- LAYER NR. 3 [ for title ] -->
                    <div class="tp-caption   tp-resizeme" id="slide-901-layer-3"
                        data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','-40','-40']"
                        data-fontsize="['80','70','60','30']" data-lineheight="['90','80','70','40']"
                        data-width="['750','750','700','400']" data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":0,"speed":1500,"frame":"0","from":"x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"power3.out"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"power3.inOut"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 2; 
                            white-space: normal; 
                            font-weight: 700;
                            color:#fff;
                            border-width:0px; font-family: 'Poppins', sans-serif;">
                        <div class="site-text-white">Cultivating Ideas for Growth</div>
                    </div>

                    <!-- LAYER NR. 5 [ for botton ] -->
                    <div class="tp-caption tp-resizeme rev-btn" id="slide-901-layer-5"
                        data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['150','150','120','60']"
                        data-lineheight="['none','none','none','none']" data-width="['300','300','300','300']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on" data-frames='[ 
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]' data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index:2;">
                        <div><a routerLink="/contact" class="site-button">Contact Us</a></div>
                    </div>


                </li>

                <!-- SLIDE 2 -->
                <li data-index="rs-902" data-transition="slidingoverlayhorizontal" data-slotamount="default"
                    data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default"
                    data-masterspeed="default" data-thumb="assets/images/main-slider/slider1//slide1.jpg"
                    data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-description="">
                    <!-- MAIN IMAGE -->

                    <img src="assets/images/main-slider/slider3/slide2.jpg" alt="" data-bgposition="center bottom"
                        data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina />


                    <!-- LAYER NR. 0 [ for overlay ] -->
                    <div class="tp-caption tp-shape tp-shapewrapper " id="slide-902-layer-0"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                        data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                        {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                        ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 1;background-color:rgba(0, 0, 0, 0.3);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                    </div>

                    <!-- LAYER NR. 2 [ for title ] -->
                    <div class="tp-caption   tp-resizeme" id="slide-902-layer-2"
                        data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
                        data-y="['middle','middle','top','top']" data-voffset="['-120','-120','190','240']"
                        data-fontsize="['20','20','20','14']" data-lineheight="['20','20','20','14']"
                        data-width="['600','500','700','400']" data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on" data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-textAlign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 2; 
                            white-space: normal; 
                            font-weight: 600;
                            color:#fff;
                            border-width:0px; font-family: 'Poppins', sans-serif;">
                        <div class="site-text-white">Form of Impression</div>
                    </div>

                    <!-- LAYER NR. 3 [ for title ] -->
                    <div class="tp-caption   tp-resizeme" id="slide-902-layer-3"
                        data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','-40','-40']"
                        data-fontsize="['80','70','60','30']" data-lineheight="['90','80','70','40']"
                        data-width="['700','600','700','350']" data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":0,"speed":1500,"frame":"0","from":"x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"power3.out"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"power3.inOut"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 2; 
                            white-space: normal; 
                            font-weight: 700;
                            color:#fff;
                            border-width:0px; font-family: 'Poppins', sans-serif;">
                        <div class="site-text-white">Heart of Perfect Farming</div>
                    </div>

                    <!-- LAYER NR. 5 [ for botton ] -->
                    <div class="tp-caption tp-resizeme rev-btn" id="slide-902-layer-5"
                        data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['150','150','120','60']"
                        data-lineheight="['none','none','none','none']" data-width="['300','300','300','300']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on" data-frames='[ 
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]' data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index:2;">
                        <div><a routerLink="/contact" class="site-button">Contact Us</a></div>
                    </div>


                </li>

                <!-- SLIDE 3 -->
                <li data-index="rs-903" data-transition="slidingoverlayhorizontal" data-slotamount="default"
                    data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default"
                    data-masterspeed="default" data-thumb="assets/images/main-slider/slider1//slide1.jpg"
                    data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-description="">
                    <!-- MAIN IMAGE -->

                    <img src="assets/images/main-slider/slider3/slide3.jpg" alt="" data-bgposition="center bottom"
                        data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina />


                    <!-- LAYER NR. 0 [ for overlay ] -->
                    <div class="tp-caption tp-shape tp-shapewrapper " id="slide-903-layer-0"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                        data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                        {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                        ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 1;background-color:rgba(0, 0, 0, 0.3);border-color:rgba(0, 0, 0, 0);border-width:0px;">
                    </div>

                    <!-- LAYER NR. 2 [ for title ] -->
                    <div class="tp-caption   tp-resizeme" id="slide-903-layer-2"
                        data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
                        data-y="['middle','middle','top','top']" data-voffset="['-120','-120','190','240']"
                        data-fontsize="['20','20','20','14']" data-lineheight="['20','20','20','14']"
                        data-width="['600','500','700','400']" data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on" data-frames='[
                            {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                            {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                            ]' data-textAlign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 2; 
                            white-space: normal; 
                            font-weight: 600;
                            color:#fff;
                            border-width:0px; font-family: 'Poppins', sans-serif;">
                        <div class="site-text-white">A New Agri Perspective </div>
                    </div>

                    <!-- LAYER NR. 3 [ for title ] -->
                    <div class="tp-caption   tp-resizeme" id="slide-903-layer-3"
                        data-x="['left','left','center','center']" data-hoffset="[100','100','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','-40','-40']"
                        data-fontsize="['80','70','60','30']" data-lineheight="['90','80','70','40']"
                        data-width="['700','700','700','400']" data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on"
                        data-frames='[{"delay":0,"speed":1500,"frame":"0","from":"x:[-175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"power3.out"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"power3.inOut"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[5,5,5,5]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style="z-index: 2; 
                            white-space: normal; 
                            font-weight: 700;
                            color:#fff;
                            border-width:0px; font-family: 'Poppins', sans-serif;">
                        <div class="site-text-white">Grow naturally, Live natural</div>
                    </div>

                    <!-- LAYER NR. 5 [ for botton ] -->
                    <div class="tp-caption tp-resizeme rev-btn" id="slide-903-layer-5"
                        data-x="['left','left','center','center']" data-hoffset="['100','100','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['150','150','120','60']"
                        data-lineheight="['none','none','none','none']" data-width="['300','300','300','300']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']" data-type="text"
                        data-responsive_offset="on" data-frames='[ 
                        {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                        ]' data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index:2;">
                        <div><a routerLink="/contact" class="site-button">Contact Us</a></div>
                    </div>


                </li>




            </ul>
            <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
        </div>
    </div>



</div>