<div class="project-img-effect-1">
    <img src="{{project.image}}" alt="" />
    <div class="wt-info  bg-white p-t20">
        <div class="project-title">
            {{project.title}}
        </div>
        <h4 class="project-title-large"><a routerLink="{{project.link}}">{{project.subtitle}}</a></h4>
        <p>
            {{project.description}}
        </p>
        <a routerLink="{{project.link}}" class="site-button-link">Read More</a>
    </div>

</div>