<div class="page-wraper">

    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->


    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-page-banner title="Blog List" subtitle="Blog List"></app-page-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- OUR BLOG START -->
        <div class="section-full  p-t120 p-b90 bg-white">
            <div class="container">

                <!-- BLOG SECTION START -->
                <div class="section-content">
                    <div class="row d-flex justify-content-center">

                        <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
                            <div class="blog-post-2-outer blog-list-style">

                                <!--Blocks-->
                                <app-blog-short [data]="blogs"></app-blog-short>

                            </div>

                            <!--Post pagination-->

                            <div class="pagination-outer text-center">
                                <div class="pagination-style1 text-center">
                                    <ul class="clearfix">
                                        <li class="prev"><a href="javascript:void(0);"><span class="fa fa-angle-double-left"></span></a>
                                        </li>
                                        <li><a href="javascript:void(0);">1</a></li>
                                        <li class="active"><a href="javascript:void(0);">2</a></li>
                                        <li><a href="javascript:void(0);">3</a></li>
                                        <li class="next"><a href="javascript:void(0);"><span class="fa fa-angle-double-right"></span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <!-- SIDE BAR START -->
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 rightSidebar  m-b30">

                            <aside class="side-bar">

                                <app-box-about-author [data]="author"></app-box-about-author>

                                <!-- SEARCH -->
                                <app-box-search></app-box-search>

                                <!-- RECENT POSTS -->
                                <app-box-recent-posts [data]="recentPosts"></app-box-recent-posts>

                                <!-- INSTA POSTS -->
                                <app-box-instagram [data]="instagramPosts"></app-box-instagram>

                                <!-- CATEGORY -->
                                <app-box-categories [data]="categories"></app-box-categories>

                                <!-- TAGS -->
                                <app-box-tags [data]="tags"></app-box-tags>

                            </aside>

                        </div>
                        <!-- SIDE BAR END -->

                    </div>
                </div>

            </div>

        </div>
        <!-- OUR BLOG END -->

    </div>
    <!-- CONTENT END -->

    <app-footer1></app-footer1>

</div>

<app-loader></app-loader>