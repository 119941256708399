import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header5',
  templateUrl: './header5.component.html',
  styleUrls: ['./header5.component.css']
})
export class Header5Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
