<div class="page-wraper">
    <!-- Header -->
    <app-header1></app-header1>
    <!-- Top Navigation -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="top: 80px; position: sticky; z-index: 1030;">
        <div class="container">
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="#overview">Overview</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#characteristics">Characteristics</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#applications">Applications</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#pipes">Pipes</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#fittings">Fittings</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#product-video">Video</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Banner Section -->
    <section id="overview" class="text-center p-5 bg-light">
        <img src="path/to/your/banner-image.jpg" alt="uPVC Pipes" class="product-image">
        <h2>uPVC Pipes</h2>
        <p class="text-left">NALPLAST uPVC pipes are well-known for their use in Water Networks, Irrigation system, Sewage & drainage systems, Industrial, Electrical & Telecommunication Cables Protection systems. The strict adherence to industry standards and the utilization of high-quality materials have established these products as a preferred option for consumers.</p>
        <p class="text-left">The pipes are produced in accordance with SASO-ISO-1452-2, SASO 14 and 15, ISO 161/1 - DIN 8061/8062, BS EN 1401 -1, BS EN 1329-1, BS 3505 / 3506, ASTM D-1785 for SCH40 & SCH80, ASTM D-2665 and D-2241 for SDR.</p>
        <p class="text-left">NALPLAST uPVC pipes are made in metric sizes from 20 mm to 400 mm diameter, with different pressure rating and dimensions.</p>
        <p class="text-left">According to SASO/EN standards, uPVC pipes are available in solvent joint sockets up to 50 mm, and both solvent and rubber joint type for larger sizes.</p>
        <p class="text-left">NALPLAST ASTM pipes come in sizes from ½” to 8”, all with plain socket, and socketed pipes can be ordered upon request.</p>
    </section>
    <!-- Characteristics Section -->
    <section id="characteristics" class="text-center py-5">
        <h3 class="mb-4">Characteristics</h3>
        <div class="row">
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture2.png" alt="characteristics 1" class="characteristics-icons">
                <p>Verified success in handling water temperatures ranging from 5°C to 60°C</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture3.png" alt="characteristics 2" class="characteristics-icons">
                <p>By utilizing high-quality raw materials, the safety of drinking water is assured.</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture4.png" alt="characteristics 3" class="characteristics-icons">
                <p>Non-flammable. Resistant to burning</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture5.png" alt="characteristics 4" class="characteristics-icons">
                <p>Efficient and simple setup. Reduces manual work</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture6.jpg" alt="characteristics 5" class="characteristics-icons">
                <p>Flow Capacity</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture7.png" alt="characteristics 6" class="characteristics-icons">
                <p>Easy & Low Installation Cost</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture8.jpg" alt="characteristics 7" class="characteristics-icons">
                <p>Strength</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture9.jpg" alt="characteristics 8" class="characteristics-icons">
                <p>Non-Conductivity</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture10.png" alt="characteristics 9" class="characteristics-icons">
                <p>Low Friction Loss</p>
            </div>
            <div class="col-md-3 col-sm-6 mb-2">
                <img src="../../../../assets/Product 1/Characteristics/Picture11.png" alt="characteristics 10" class="characteristics-icons">
                <p>Chemical Resistance</p>
            </div>
        </div>
    </section>
    <!-- Applications Section -->
    <section id="applications" class="text-center py-5 bg-light">
        <h3 class="mb-4">Applications</h3>
        <div class="row">
            <div class="col-md-1 col-sm-6">
                <p></p>
            </div>
            <div class="col-md-2 col-sm-6">
                <img src="../../../../assets/Product 1/Applications/Picture13.png" alt="Application 1" class="application-icons">
                <p>Cold water plumbing lines can be installed both indoors and outdoors</p>
            </div>
            <div class="col-md-2 col-sm-6">
                <img src="../../../../assets/Product 1/Applications/Picture14.png" alt="Application 2" class="application-icons">
                <p>Water treatment facilities for reverse osmosis (RO) and deionized (DM) water production</p>
            </div>
            <div class="col-md-2 col-sm-6">
                <img src="../../../../assets/Product 1/Applications/Picture15.png" alt="Application 3" class="application-icons">
                <p>For concealed, down take & terrace looping</p>
            </div>
            <div class="col-md-2 col-sm-6">
                <img src="../../../../assets/Product 1/Applications/Picture16.png" alt="Application 4" class="application-icons">
                <p>Public services, swimming pools, and industrial uses</p>
            </div>
            <div class="col-md-2 col-sm-6">
                <img src="../../../../assets/Product 1/Applications/Picture17.png" alt="Application 5" class="application-icons">
                <p>Apartment complexes, office towers</p>
            </div>
            <div class="col-md-1 col-sm-6">
                <p></p>
            </div>
        </div>
    </section>

    <!-- Pipes Section -->
    <section id="pipes" class="text-center py-5">
        <h3 class="mb-4">Pipes</h3>
        <img src="path/to/pipes-image.jpg" alt="Pipes" class="product-image">
        <!-- More Pipes -->
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <img src="path/to/pipe-image2.jpg" alt="Pipe 2" class="product-image">
                <p>Pipe 2 Description</p>
            </div>
            <div class="col-md-4 col-sm-6">
                <img src="path/to/pipe-image3.jpg" alt="Pipe 3" class="product-image">
                <p>Pipe 3 Description</p>
            </div>
        </div>
    </section>

    <!-- Fittings Section -->
    <section id="fittings" class="text-center py-5 bg-light">
        <h3 class="mb-4">Fittings</h3>
        <div class="row">
            <div class="col-md-2 col-sm-4 fittings">
                <img src="path/to/fitting1.jpg" alt="Fitting 1">
                <p>Fitting 1</p>
            </div>
            <div class="col-md-2 col-sm-4 fittings">
                <img src="path/to/fitting2.jpg" alt="Fitting 2">
                <p>Fitting 2</p>
            </div>
            <div class="col-md-2 col-sm-4 fittings">
                <img src="path/to/fitting3.jpg" alt="Fitting 3">
                <p>Fitting 3</p>
            </div>
            <!-- More Fittings -->
            <div class="col-md-2 col-sm-4 fittings">
                <img src="path/to/fitting4.jpg" alt="Fitting 4">
                <p>Fitting 4</p>
            </div>
        </div>
    </section>
    <!-- Product Video Section -->
    <section id="product-video" class="text-center">
        <div class="video-container">
            <iframe src="https://www.youtube.com/embed/your-video-id" frameborder="0" allowfullscreen></iframe>
        </div>
        <p class="mt-3">Watch our uPVC Pipes in Action</p>
    </section>
    <!-- Footer -->
    <app-footer1></app-footer1>
</div>
<app-loader></app-loader>