<div class="service-icon-box-two">

    <div class="wt-icon-box-wraper">
        <div class="icon-xl inline-icon">
            <span class="icon-cell site-text-primary"><i class="{{data.icon}}"></i></span>
        </div>
    </div>

    <div class="service-icon-box-title">
        <h4 class="wt-title"><a routerLink="{{data.link}}">{{data.title}}</a>
        </h4>
    </div>

    <div class="service-icon-box-content">
        <p>
            {{data.description}}
        </p>
        <!-- <a routerLink="{{data.link}}" class="site-button-link site-text-primary">Read
            More</a> -->
    </div>

</div>