<div class="section-full p-t50 p-b30 bg-no-repeat bg-center bg-gray-light">
    <div class="about-section-one">
        <ng-template ngFor let-data [ngForOf]="data">
            <div class="container">
                <div class="section-content">
                    <div class="row justify-content-center d-flex">
                        <!-- <div class="col-lg-6 col-md-12 m-b30">
                            <div class="about-max-one">
                                <div class="about-max-one-media"><img src="{{data.sectionImage}}" alt=""></div>
                            </div>
                        </div> -->
                        <div class="col-lg-12 col-md-12 m-b10">
                            <div class="about-section-one-right">
                                <div class="section-head left wt-small-separator-outer">

                                </div>
                                <div class="about-one">
                                    <!-- <div class="about-year">
                                        <div class="row">
                                            <div class="col">
                                                <h3>{{data.subtitle}}</h3>
                                                <div class="about-year-info">
                                                    <span class="text-center">{{data.yearsOfSuccess}}</span>
                                                    <p>Year of Success</p>
                                                </div>
                                            </div>
                                            <div class="col">

                                                <p>
                                                    {{data.description}}
                                                </p>
                                            </div>
                                        </div>


                                    </div> -->
                                    <!-- 
                                    <div class="row">

                                       <div class="col-lg-4 col-md-12" *ngFor = "let item of data.features">
                                            <div class="wt-icon-box-wraper left bg-black m-b30">
                                                <div class="icon-content">
                                                    <h4 class="wt-tilte">{{item.title}}</h4> -->
                                    <!-- <p>{{item.description}}</p> -->
                                    <!-- </div>
                                            </div>
                                        </div> -->
                                    <!-- <div class="col-lg-12 col-md-12">
                                            <section class="our-target-section text-center">
                                                <div class="container text-center">
                                                    <div class="target-list">
                                                        <div class="target-item">Top Class</div>
                                                        <div class="target-item">State-Of-The-Art Setup</div>
                                                        <div class="target-item">Reliable Brand</div>
                                                </div>  
                                                </div>
                                            </section>
                                        </div> 
                                        <section class="points-section">
                                            <div class="point-item">
                                                <h4 class="point-title">Reliable Brand</h4>
                                                <p class="point-description">Building trust and reliability with our
                                                    customers.</p>
                                            </div>
                                            <div class="point-item">
                                                <h4 class="point-title">Top Class</h4>
                                                <p class="point-description">Providing top-notch quality products and
                                                    services.</p>
                                            </div>
                                            <div class="point-item">
                                                <h4 class="point-title">State-Of-The-Art Setup</h4>
                                                <p class="point-description">Utilizing cutting-edge technology and
                                                    infrastructure.</p>
                                            </div>
                                        </section>
                                    </div> -->
                                    <p>
                                        {{data.description2}}
                                    </p>
                                    <p>
                                        {{data.description3}}
                                    </p>
                                    <p>
                                        {{data.description4}}
                                    </p>
                                    <p>
                                        {{data.description5}}
                                    </p>
                                    <!-- <a routerLink="" class="site-button sb-bdr-dark">Learn More</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>