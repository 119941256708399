<div class="section-full p-t120 p-b90 bg-white">

    <div class="container">

        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer">
            <div class="wt-small-separator site-text-primary">
                <div class="sep-leaf-left"></div>
                <div>{{data.title}}</div>
            </div>
            <h2>{{data.subtitle}}</h2>
        </div>
        <!-- TITLE END-->


        <!-- IMAGE CAROUSEL START -->
        <div class="section-content">


            <div class="row">

                <div class="col-md-6 col-sm-6 m-b30" *ngFor="let item of data.blogs">
                    <div class="blog-post latest-blog-4">
                        <div class="wt-post-media wt-img-effect zoom-slow">
                            <a routerLink="{{item.link}}"><img src="{{item.image}}" alt=""></a>
                        </div>
                        <div class="wt-post-info">
                            <div class="post-date"> <strong>{{item.date}}</strong></div>

                            <div class="wt-post-meta">
                                <ul class="clearfix">
                                    <li class="post-author">
                                        <div class="post-author-pic">
                                            <span><img src="{{item.authorImage}}" alt=""></span>
                                            <span><strong> By</strong> <a routerLink="/our-team-detail">{{item.author}}</a></span>
                                        </div>
                                    </li>
                                    <li class="post-comment"><i class="fa fa fa-comments site-text-primary"></i>
                                        <a routerLink="/our-team-detail">{{item.comments}} Comment</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="wt-post-title">
                                <h4 class="post-title m-b20"><a routerLink="{{item.link}}">
                                    {{item.title}}
                                </a></h4>
                            </div>


                            <div class="readmore-line">
                                <a routerLink="{{item.link}}" class="site-button-link site-text-primary">Read More</a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>