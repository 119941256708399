<div class="slider-outer">
    <div id="rev_slider_1050_1_wrapper" class="rev_slider_wrapper fullscreen-container" data-alias="webproduct-light"
        data-source="gallery" style="background-color:transparent;padding:0px;">
        <!-- START REVOLUTION SLIDER 5.4.1 fullscreen mode -->
        <div id="rev_slider_1050_1" class="rev_slider fullscreenbanner" style="display:none;" data-version="5.4.1">
            <ul>
                <!-- SLIDE 1 -->
                <li data-index="rs-2938" data-transition="slidevertical" data-slotamount="1" data-hideafterloop="0"
                    data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="1500"
                    data-thumb="assets/images/main-slider/slider5/slide1.jpg" data-rotate="0" data-fstransition="fade"
                    data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off" data-title="Intro"
                    data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
                    data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/background/bg11.jpg" alt="" data-bgposition="center center"
                        data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina>
                    <!-- LAYERS -->

                    <!-- LAYER NR. 1 [ for overlay ] -->
                    <div class="tp-caption tp-shape tp-shapewrapper " id="slide-901-layer-0"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                        data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                    {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                    {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                    ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 1;background-color:rgba(0, 0, 0, 0.7);">
                    </div>

                    <!-- LAYER NR. 1 Men Img -->
                    <!-- <div class="tp-caption   tp-resizeme" id="slide-2938-layer-1"
                        data-x="['right','right','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-5','-5','-5','-5']"
                        data-width="none" data-height="none" data-whitespace="nowrap" data-type="image"
                        data-responsive_offset="on"
                        data-frames='[{"from":"x:right;","speed":1500,"to":"o:1;","delay":2500,"ease":"Power3.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['right','right','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 5;text-transform:left;border-width:0px;"><img
                            src="assets/images/main-slider/slider5/pic1.png" alt="" data-ww="['391px','270px','182px','121px']"
                            data-hh="['645px','445px','300px','200px']" width="391" height="645" data-no-retina> 
                        </div> -->


                    <!-- LAYER NR. 7 -->
                    <div class="tp-caption WebProduct-Title   tp-resizeme" id="slide-2938-layer-7"
                        data-x="['left','left','center','center']" data-hoffset="['50','50','0','0']"
                        data-y="['middle','middle','top','top']" data-voffset="['-200','-160','137','137']"
                        data-fontsize="['16','16','16','14']" data-lineheight="['16','16','16','14']"
                        data-width="['700','700','700','300']" data-height="['none','none','none','none']"
                        data-whitespace="nowrap" data-type="text" data-responsive_offset="on"
                        data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 11; font-family: 'Poppins', sans-serif; font-weight: 600; color:#fff;"><span
                            class="site-bg-primary p-a10">WELCOME TO NLP FACTORY</span></div>

                    <!-- LAYER NR. 8 -->
                    <div class="tp-caption WebProduct-SubTitle   tp-resizeme" id="slide-2938-layer-10"
                        data-x="['left','left','center','center']" data-hoffset="['50','50','0','0']"
                        data-y="['middle','middle','top','top']" data-voffset="['0','0','200','200']"
                        data-fontsize="['90','70','70','38']" data-lineheight="['90','70','70','38']"
                        data-width="['750','700','700','300']" data-height="['none','none','none','none']"
                        data-type="text" data-responsive_offset="on"
                        data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1250,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 12 ; font-family: 'Poppins', sans-serif; color:#fff; font-weight: 800; text-transform:uppercase; white-space:normal">
                        One Industry All Factorial Solution</div>

                    <!-- LAYER NR. 10 -->
                    <div class="tp-caption" id="slide-2938-layer-8" data-x="['left','left','center','center']"
                        data-hoffset="['50','50','0','0']" data-y="['middle','middle','top','top']"
                        data-voffset="['200','200','456','356']" data-width="none" data-height="none"
                        data-whitespace="nowrap" data-type="button" data-responsive_offset="on" data-responsive="off"
                        data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 14;"><a routerLink="/about" class="site-button">About Us</a>
                    </div>
                </li>


                <!-- SLIDE 2 -->
                <li data-index="rs-2939" data-transition="slidevertical" data-slotamount="1" data-hideafterloop="0"
                    data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="1500"
                    data-thumb="assets/images/main-slider/slider5/slide1.jpg" data-rotate="0" data-fstransition="fade"
                    data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off" data-title="Intro"
                    data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
                    data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/background/bg12.jpg" alt="" data-bgposition="center center"
                        data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina>
                    <!-- LAYERS -->

                    <!-- LAYER NR. 1 [ for overlay ] -->
                    <div class="tp-caption tp-shape tp-shapewrapper " id="slide-2939-layer-0"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                        data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                    {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                    {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                    ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 1;background-color:rgba(0, 0, 0, 0.5);">
                    </div>

                    <!-- LAYER NR. 1 Men Img -->
                    <!-- <div class="tp-caption   tp-resizeme" id="slide-2939-layer-1"
                        data-x="['right','right','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['bottom','bottom','bottom','bottom']" data-voffset="['-5','-5','-5','-5']"
                        data-width="none" data-height="none" data-whitespace="nowrap" data-type="image"
                        data-responsive_offset="on"
                        data-frames='[{"from":"x:right;","speed":1500,"to":"o:1;","delay":2500,"ease":"Power3.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 5;text-transform:left;border-width:0px;"><img
                            src="assets/images/main-slider/slider5/pic2.png" alt="" data-ww="['388px','298px','182px','121px']"
                            data-hh="['650px','500px','305px','203px']" width="388" height="650" data-no-retina> </div> -->


                    <!-- LAYER NR. 7 -->
                    <div class="tp-caption WebProduct-Title   tp-resizeme" id="slide-2939-layer-7"
                        data-x="['left','left','center','center']" data-hoffset="['50','50','0','0']"
                        data-y="['middle','middle','top','top']" data-voffset="['-200','-160','137','137']"
                        data-fontsize="['16','16','16','14']" data-lineheight="['16','16','16','14']"
                        data-width="['700','700','700','300']" data-height="['none','none','none','none']"
                        data-whitespace="nowrap" data-type="text" data-responsive_offset="on"
                        data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 11; font-family: 'Poppins', sans-serif; font-weight: 600; color:#fff;"><span
                            class="site-bg-primary p-a10">RELIABLE INDUSTRIAL SOLUTION</span></div>

                    <!-- LAYER NR. 8 -->
                    <div class="tp-caption WebProduct-SubTitle   tp-resizeme" id="slide-2939-layer-10"
                        data-x="['left','left','center','center']" data-hoffset="['50','50','0','0']"
                        data-y="['middle','middle','top','top']" data-voffset="['0','0','200','200']"
                        data-fontsize="['90','70','70','38']" data-lineheight="['90','70','70','38']"
                        data-width="['700','700','700','300']" data-height="['none','none','none','none']"
                        data-type="text" data-responsive_offset="on"
                        data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1250,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 12 ; font-family: 'Poppins', sans-serif; color:#fff; font-weight: 800; text-transform:uppercase; white-space:normal">
                        Build Everything With Passion</div>

                    <!-- LAYER NR. 10 -->
                    <div class="tp-caption" id="slide-2939-layer-8" data-x="['left','left','center','center']"
                        data-hoffset="['50','50','0','0']" data-y="['middle','middle','top','top']"
                        data-voffset="['200','200','456','356']" data-width="none" data-height="none"
                        data-whitespace="nowrap" data-type="button" data-responsive_offset="on" data-responsive="off"
                        data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['left','left','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 14;"><a routerLink="/contact" class="site-button">Contact Us</a>
                    </div>
                </li>


                <!-- SLIDE 3 -->
                <li data-index="rs-2940" data-transition="slidevertical" data-slotamount="1" data-hideafterloop="0"
                    data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="1500"
                    data-thumb="assets/images/main-slider/slider5/slide1.jpg" data-rotate="0" data-fstransition="fade"
                    data-fsmasterspeed="1500" data-fsslotamount="7" data-saveperformance="off" data-title="Intro"
                    data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6=""
                    data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                    <!-- MAIN IMAGE -->
                    <img src="assets/images/banner/1.jpg" alt="" data-bgposition="center center"
                        data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina>
                    <!-- LAYERS -->

                    <!-- LAYER NR. 1 [ for overlay ] -->
                    <div class="tp-caption tp-shape tp-shapewrapper " id="slide-2940-layer-0"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                        data-width="full" data-height="full" data-whitespace="nowrap" data-type="shape"
                        data-basealign="slide" data-responsive_offset="off" data-responsive="off" data-frames='[
                    {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                    {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                    ]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 1;background-color:rgba(0, 0, 0, 0.7);">
                    </div>


                    <!-- LAYER NR. 7 -->
                    <div class="tp-caption WebProduct-Title   tp-resizeme" id="slide-2940-layer-7"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['-150','-140','-150','-100']"
                        data-fontsize="['16','16','16','16']" data-lineheight="['16','16','16','18']"
                        data-width="['700','700','700','400']" data-height="['none','none','none','none']"
                        data-whitespace="nowrap" data-type="text" data-responsive_offset="on"
                        data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1000,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 11; font-family: 'Poppins', sans-serif; font-weight: 600; color:#fff;"><span
                            class="site-bg-primary p-a10">BUILD ANYTHING WITH US</span></div>

                    <!-- LAYER NR. 8 -->
                    <div class="tp-caption WebProduct-SubTitle   tp-resizeme" id="slide-2940-layer-10"
                        data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']" data-voffset="['0','0','0','0']"
                        data-fontsize="['90','70','70','38']" data-lineheight="['90','70','70','38']"
                        data-width="['1000','700','700','400']" data-height="['none','none','none','none']"
                        data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+890","split":"chars","splitdelay":0.03,"speed":300,"frame":"0","from":"sX:2;sY:2;opacity:0;fb:5px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},
                        {"delay":"wait","speed":1000,"frame":"999","to":"opacity:0;fb:10px;","ease":"Power4.easeOut"}]'
                        data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 12 ; font-family: 'Poppins', sans-serif; color:#fff; font-weight: 800; text-transform:uppercase; white-space:normal">
                        Build Your Industry With Us.
                    </div>

                    <!-- LAYER NR. 10 -->
                    <div class="tp-caption" id="slide-2940-layer-8" data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                        data-voffset="['150','150','160','100']" data-width="none" data-height="none"
                        data-whitespace="nowrap" data-type="button" data-responsive_offset="on" data-responsive="off"
                        data-frames='[{"from":"x:-50px;opacity:0;","speed":1000,"to":"o:1;","delay":1750,"ease":"Power2.easeOut"},{"delay":"wait","speed":1500,"to":"opacity:0;","ease":"Power4.easeIn"}]'
                        data-textAlign="['center','center','center','center']" data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]"
                        style="z-index: 14;"><a routerLink="/contact" class="site-button">Contact Us</a>
                    </div>
                </li>


            </ul>

            <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
        </div>
    </div>
</div>