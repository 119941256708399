<!-- HEADER START -->
<header class="site-header header-style-4 mobile-sider-drawer-menu">

    <div class="sticky-header main-bar-wraper  navbar-expand-lg">
        <div class="main-bar">

            <div class="container-fluid clearfix">

                <div class="logo-header">
                    <div class="logo-header-inner logo-header-one">
                        <a routerLink="/index">
                            <img src="assets/images/logo 1.png" alt="">
                        </a>
                    </div>
                </div>

                <!-- NAV Toggle Button -->
                <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button"
                    class="navbar-toggler collapsed">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar icon-bar-first"></span>
                    <span class="icon-bar icon-bar-two"></span>
                    <span class="icon-bar icon-bar-three"></span>
                </button>

                <!-- MAIN Vav -->
                <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">

                    <ul class=" nav navbar-nav">
                        <li class="active has-child"><a href="javascript:void(0);">Home</a>
                            <div class="fa fa-angle-right submenu-toogle"></div>
                            <ul class="sub-menu">
                                <li><a routerLink="/index">Option 1</a></li>
                                <li><a routerLink="/index2">Option 2</a></li>
                                <li><a routerLink="/index3">Option 3</a></li>
                                <li><a routerLink="/index4">Option 4</a></li>
                                <li><a routerLink="/index5">Option 5</a></li>
                            </ul>
                        </li>

                        <li class="has-child">
                            <a href="javascript:void(0);">Company</a>
                            <div class="fa fa-angle-right submenu-toogle"></div>
                            <ul class="sub-menu">
                                <li><a href="javascript:void(0);">About</a>
                                    <div class="fa fa-angle-right submenu-toogle"></div>
                                    <ul class="sub-menu">
                                        <li><a routerLink="/about1">About us 1</a></li>
                                        <li><a routerLink="/about2">About us 2</a></li>
                                    </ul>
                                </li>
                                <li><a href="javascript:void(0);">Services</a>
                                    <div class="fa fa-angle-right submenu-toogle"></div>
                                    <ul class="sub-menu">
                                        <li><a routerLink="/services">Services</a></li>
                                        <li><a routerLink="/service-detail">Services Detail</a></li>
                                    </ul>
                                </li>
                                <li><a href="javascript:void(0);">Team</a>
                                    <div class="fa fa-angle-right submenu-toogle"></div>
                                    <ul class="sub-menu">
                                        <li><a routerLink="/our-team">Team</a></li>
                                        <li><a routerLink="/our-team-detail">Team Detail</a></li>
                                    </ul>
                                </li>
                                <li><a routerLink="/faq">Faq</a></li>
                                <li><a routerLink="/pricing">Pricing</a></li>
                                <li><a routerLink="/error404">Error-404</a></li>
                                <li><a routerLink="/icon-font">Icon Font</a></li>
                            </ul>
                        </li>
                        <li class="has-child"><a href="javascript:void(0);">Projects</a>
                            <div class="fa fa-angle-right submenu-toogle"></div>
                            <ul class="sub-menu">
                                <li><a routerLink="/project1">Projects 1</a></li>
                                <li><a routerLink="/project2">Projects 2</a></li>

                            </ul>
                        </li>
                        <li class="has-child"><a href="javascript:void(0);">Products</a>
                            <div class="fa fa-angle-right submenu-toogle"></div>
                            <ul class="sub-menu">
                                <li><a routerLink="/project1">Product 1</a></li>
                                <li><a routerLink="/project2">Product 2</a></li>

                            </ul>
                        </li>

                        <li class="has-child"><a href="javascript:void(0);">Blog</a>
                            <div class="fa fa-angle-right submenu-toogle"></div>
                            <ul class="sub-menu">
                                <li><a routerLink="/blog-grid">Blog Grid</a></li>
                                <li><a routerLink="/blog-list">Blog List</a></li>
                                <li><a routerLink="/blog-single">Blog Single</a></li>
                            </ul>
                        </li>
                        <li><a routerLink="/contact">Contact</a></li>

                    </ul>

                </div>

                <!-- Header Right Section-->
                <div class="extra-nav header-1-nav">
                    <div class="extra-cell one">
                        <div class="header-line-btn menu-btn">
                            <a href="javascript:void(0);" class="header-line-icon">
                                <span class="icon-line icon-bar-first"></span>
                                <span class="icon-line icon-bar-two"></span>
                                <span class="icon-line icon-bar-three"></span>
                            </a>
                        </div>
                    </div>
                    <div class="extra-cell two">
                        <div class="header-search">
                            <a id="#search" class="header-search-icon"><i class="fa fa-search"></i></a>
                        </div>
                    </div>
                </div>


                <!-- Contact Nav -->
                <div class="contact-slide-hide bg-cover"
                    style="background-image:url(assets/images/background/bg11.jpg);">
                    <div class="contact-nav">
                        <a href="javascript:void(0)" class="contact_close">&times;</a>
                        <div class="contact-nav-form p-a30">
                            <div class="contact-info   m-b30">

                                <div class="wt-icon-box-wraper center p-b30">
                                    <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-phone"></i></div>
                                    <div class="icon-content">
                                        <h4 class="m-t0  wt-title">Phone number</h4>
                                        <p>+966 11 4655880</p>
                                    </div>
                                </div>

                                <div class="wt-icon-box-wraper center p-b30">
                                    <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-envelope"></i></div>
                                    <div class="icon-content">
                                        <h4 class="m-t0 wt-title">Email address</h4>
                                        <p>info@nalplast.com</p>
                                    </div>
                                </div>

                                <div class="wt-icon-box-wraper center p-b30">
                                    <div class="icon-xs m-b20 scale-in-center"><i class="fa fa-map-marker"></i></div>
                                    <div class="icon-content">
                                        <h4 class="m-t0  wt-title">Address info</h4>
                                        <p>Al Faruq, Riyadh 12863, Saudi Arabia</p>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <span>&copy; 2024 NALPLAST</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- SITE Search -->
                <div id="search">
                    <span class="close"></span>
                    <form role="search" id="searchform" action="/search" method="get" class="radius-xl">
                        <div class="input-group">
                            <input class="form-control" value="" name="q" type="search" placeholder="Type to search" />
                            <span class="input-group-append"><button type="button" class="search-btn"><i
                                        class="fa fa-paper-plane"></i></button></span>
                        </div>
                    </form>


                </div>



            </div>


        </div>
    </div>


    <div class="full-menu bg-cover bg-no-repeat" style="background-image: url(assets/images/background/bg11.jpg);">
        <button type="button" class="full-menu-close"></button>
        <div class="vertical-content-wrap">
            <div class="vertical-centered">
                <!-- MAIN Vav -->
                <div class="contact-form-outer">
                    <form class="cons-contact-form" method="post" action="form-handler2.php">


                        <!-- TITLE START-->
                        <div class="section-head left wt-small-separator-outer">
                            <h3 class="wt-title m-b30">Contact Form</h3>
                            <p>Feel Free to Contact us any time. we will get back to you as soon as we can't</p>
                        </div>
                        <!-- TITLE END-->

                        <div class="row">

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input name="username" type="text" required="" class="form-control"
                                        placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input name="email" type="text" class="form-control" required=""
                                        placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input name="phone" type="text" class="form-control" required=""
                                        placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input name="subject" type="text" class="form-control" required=""
                                        placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" rows="4"
                                        placeholder="Message"></textarea>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <button type="submit" class="site-button sb-bdr-dark">Submit Now</button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


</header>
<!-- HEADER END -->