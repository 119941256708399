<div class="section-full p-t120 p-b90" style="background-image:url(assets/images/background/bg-dott.png);">

    <div class="container">

        <!-- TITLE START-->
        <div class="section-head center wt-small-separator-outer text-center text-white">
            <div class="wt-small-separator site-text-secondry">
                <div class="sep-leaf-left"></div>
                <div>Pricing</div>
            </div>
            <h2>Our Pricing Plan</h2>
        </div>
        <!-- TITLE END-->

        <div class="section-content">
            <div class="pricing-block-outer">
                <div class="row no-gutters">

                    <div class="col-lg-4 col-md-6 site-bg-gray-light" *ngFor="let item of data">
                        <div class="pricing-table-1">
                            <div class="p-table-title">
                                <h4 class="wt-title">
                                    {{item.plan}} Plan
                                </h4>
                            </div>
                            <div class="p-table-price">
                                <span>{{item.price}}</span>
                                <p>Per/{{item.tenure}}</p>
                            </div>
                            <div class="p-table-list">
                                <ul>
                                    <li *ngFor="let feature of item.features">{{feature.name}}</li>
                                </ul>
                            </div>

                            <div class="p-table-btn">
                                <a routerLink="{{item.purchaseLink}}" class="site-button sb-bdr-dark">Purchase Now</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>