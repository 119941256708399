<div class="section-full p-t120 p-b90 bg-gray">
    <div class="container">
        <div class="section-content">

            <div class="owl-carousel project-slider2 dark-dotts-line center m-b30">

                <app-project-box3 class="item" *ngFor="let project of projects" [project]="project"></app-project-box3>

            </div>

        </div>
    </div>

</div>