<div class="page-wraper">

    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->


    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-page-banner title="Pricing" subtitle="Pricing"></app-page-banner>
        <!-- INNER PAGE BANNER END -->


        <!-- PRICING TABLE SECTION START -->
        <app-pricing1 [data]="pricing"></app-pricing1>
        <!-- PRICING TABLE SECTION END -->


        <!-- PRICING TABLE SECTION START -->
        <app-pricing2 [data]="pricing"></app-pricing2>
        <!-- PRICING TABLE SECTION END -->

    </div>
    <!-- CONTENT END -->

    <app-footer1></app-footer1>

</div>

<app-loader></app-loader>