<div class="page-content">

    <!-- Error SECTION START -->
    <div class="section-full page-notfound-outer">
        <div class="container-fluid">
            <div class="section-content">

                <div class="page-notfound">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12">
                            <div class="page-notfound-content">
                                <h3 class="error-title">Error</h3>
                                <strong>404</strong>
                                <h3 class="error-comment">Oops! Looks like the page is gone.</h3>
                                <p>The page you are looking for might have been removed had its name changed
                                or is temporarily unavailable </p>
                                <a routerLink="/index" class="site-button sb-bdr-dark">Back to Home</a>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12">                          
                            <div class="page-notfound-media text-right">
                                <img src="assets/images/error-bg.png" alt="">
                            </div>
                        </div>      
                    </div>                         
                </div>

            </div>
        </div>
    </div>   
    <!-- Error  SECTION END -->            
    

</div>

<app-loader></app-loader>