<div class="page-wraper">
    <app-header1></app-header1>
    <!-- CONTENT START -->
    <div class="page-content">
        <!-- INNER PAGE BANNER -->
        <!-- <app-page-banner title="About Us" subtitle="About Us"></app-page-banner> -->
        <!-- INNER PAGE BANNER END -->
        <app-page-banner title="About Us" subtitle="About"></app-page-banner>
        <!-- VISION SECTION END -->
        <app-about-section2 [data]="aboutSection"></app-about-section2>

        <div class="container">
            <div class="row">
                <div class="col">
                    <section class="best-in-art-wrp common-pd-bottom" id="infrastructure">
                        <div class="row row-eq-height">
                            <div class="col-md-6 order-md-12">
                                <div class="best-rhs">
                                    <img class="img-fluid" src="assets/images/22.jpg" alt="Prince Piping systems">
                                </div>
                            </div>
                            <div class="col-md-6 order-md-1">
                                <div class="best-lhs">
                                    <h3 class="ttle"><span class="red">Top Class.</span> State-Of-The-Art Setup. And
                                        .Reliable Brand.
                                    </h3>
                                    <p class="body-copy">Nalplast Is a well-structured organization equipped with the
                                        most advanced and reliable machinery that creates a
                                        zero-defect manufacturing process. our fully computerized material mixing
                                        facilities can provide the constant
                                        homogeneous material for production. Through its closed material supply system
                                        Nalplast also provide a contamination
                                        free material for production lines. </p>

                                    <div class="row">

                                        <div class="col-md-6">
                                            <div class="best-grid-bx">
                                                <i class="flaticon-checked"></i>
                                                <p class="body-copy">High quality plasticizing capacity, due to
                                                    specially
                                                    designed
                                                    screw
                                                    barrels, ensuring homogeneity of material.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="best-grid-bx">
                                                <i class="flaticon-checked"></i>
                                                <p class="body-copy">Precisely controlled processing parameters ensuring
                                                    consistent
                                                    quality
                                                    output.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="best-grid-bx">
                                                <i class="flaticon-checked"></i>
                                                <p class="body-copy">Higher operating speeds that increase production
                                                    capacity.</p>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="best-grid-bx">
                                                <i class="flaticon-checked"></i>
                                                <p class="body-copy">Power-saving measures.</p>
                                            </div>
                                        </div>

                                        <div class="clearfix"></div>
                                    </div>

                                </div>
                            </div>

                            <div class="clearfix"></div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <!-- ABOUT ONE SECTION START -->
        <app-about-section1 [data]="aboutUsSection"></app-about-section1>
        <!-- ABOUT ONE SECTION END -->
        <!-- <app-features2 [data]="featuresSection"></app-features2> -->

        <!-- VISION SECTION START -->


        <!-- SOME FACTS START -->
        <app-facts2 [data]="factsSection"></app-facts2>
        <!-- SOME FACTS END -->

        <!-- OUR TARGET SECTION -->
        <section class="our-target-section">
            <div class="container">
                <h2>OUR TARGET</h2>
                <div class="target-list">
                    <div class="target-item">Building Nalplast Legacy</div>
                    <div class="target-item">Pan Saudi Arabia Expansion</div>
                    <div class="target-item">Evolution Towards A Trusted Brand</div>
                </div>
            </div>
        </section>
        <!-- END OUR TARGET SECTION -->

        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12">
                <section class="distribution-section" style="padding: 40px 20px;">
                    <div class="container">
                        <h3 class="text-center">Strategic Distribution & Factory Network</h3>
                        <p class="text-left" style="padding: 40px 20px;">Nalplast is targeting a network of distributors
                            throughout Saudi Arabia to exceed 2500 outlets and to be able to serve its customers with
                            its innovative products and after-sales services.</p>
                    </div>
                </section>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12">
                <section class="partnerships-section" style="padding: 0px 20px;">
                    <div class="container">
                        <h3 class="text-center">Creating Value by Embracing Strategic Partnerships</h3>
                        <p class="text-left" style="padding: 40px 20px;">One of the most recognized powers that makes
                            Nalplast one of the top players in its industry is the strategic partnerships with renowned
                            names from the neighboring countries, those partners who have a vast presence in the
                            industry and gained high accreditation from customers and other authentication identities.
                            This partnership leverages the strength of Nalplast and helps it to provide its customers
                            with a full range of high-end piping systems material.</p>
                    </div>
                </section>
            </div>
        </div>

        <!-- TESTIMONIAL SECTION START -->
        <!-- <app-testimonial1 [data]="testimonialsSection"></app-testimonial1> -->
        <!-- TESTIMONIAL SECTION END -->

        <!-- BUILD FUTURE START -->
        <!-- <app-buildfuture1 [data]="buildFutureSection"></app-buildfuture1> -->
        <!-- BUILD FUTURE END -->
    </div>

    <app-team1 [data]="teamSection1"></app-team1>

    <div class="section-full p-t120 p-b90 site-bg-gray-light">
        <div class="container-fluid">
            <!-- TITLE START -->
            <div class="section-head center wt-small-separator-outer">
                <div class="wt-small-separator site-text-primary">
                    <div class="sep-leaf-left"></div>
                    <div>Infrastructure</div>
                </div>
            </div>
            <!-- TITLE END -->
            
            <div class="section-content">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <p class="text-left" style="padding: 20px;">
                                With a land area of 20,000 square meters, the NALPLAST factory was established in 2023 as a revival of the (ASPPCO 2015-2020). It stands as one of the newest state-of-the-art factories in the Kingdom of Saudi Arabia, aligning with the Smart Industry Readiness Index (SIRI) program as outlined in Vision 2030. At NALPLAST, we are dedicated to showcasing our latest advancements in plastic pipes and fittings. To achieve this, we have meticulously integrated state-of-the-art machinery, positioning the factory uniquely in the market.
                            </p>
                        </div>
                        <div class="col-md-6">
                            <img src="../../../../assets/AboutUsImages/2023_02_24_135891_1677225789._large-78rmp8nz3tdrup3cjvoxa1zqss90nwawbb113e1jwao.jpg" alt="Image 7" class="img-fluid">
                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-6"><br /><br /><br /><br />
                            <img src="../../../../assets/AboutUsImages/Lovepik_com-500379019-green-environment-background-of-the-earth-home.jpg" alt="Image 7" class="img-fluid">
                        </div>
                        <div class="col-md-6">
                            <p class="text-left" style="padding: 20px;">
                                In a conscientious effort towards environmental sustainability, significant modifications have been made to the production facility. These changes aim to minimize emissions, fulfill environmental obligations, and consequently enhance the cleanliness of the work environment. This strategic shift not only serves to elevate the satisfaction and well-being of our employees but also contributes to the overall enhancement of production quality.
                            </p>
                            <p class="text-left" style="padding: 5px 20px;">
                                The significant surge in construction projects driven by the Kingdom's vision for 2030 has spurred NALPLAST to introduce innovative products aimed at enhancing the efficiency of piping systems while simultaneously reducing project costs. Among the latest additions are cutting-edge products that have demonstrated superior performance on an international scale compared to traditional piping systems in specific applications. Noteworthy examples include PVC foam core pipes and Double Wall Corrugated (DWC) polyethylene pipes designed for drainage and sewage systems, complementing the existing range of PVC pipes, fittings, Corrugated Optical Duct (COD), and HDPE pipes manufactured by NALPLAST.                            </p>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-4">
                            <img src="../../../../assets/AboutUsImages/PVC foam core pipes.jpg" height="300" width="300" alt="Image 2" class="img-fluid">
                        </div>
                        <div class="col-md-4"> <br/><br/>
                            <img src="../../../../assets/AboutUsImages/2bafd402e41e.jpeg" height="300" width="300" alt="Image 1" class="img-fluid">
                        </div>
                        <div class="col-md-4">
                            <img src="../../../../assets/AboutUsImages/Duct-1200x1200-4-1170x1170.jpg" height="300" width="300" alt="Image 3" class="img-fluid">
                        </div>
                    </div>
                    <br /><br /><br />
                    <div class="row mt-4">
                        <div class="col-md-6"><br /><br /><br /><br />
                            <p>NALPLAST upholds rigorous quality control standards and ensures the certification of its products by numerous reputable international third-party organizations. The company maintains round-the-clock quality checks and enforces stringent safety protocols within its facilities, adhering to a zero-tolerance policy towards any compromises in this regard.</p>
                        </div>
                        <div class="col-md-6">
                            <img src="../../../../assets/AboutUsImages/003-q2ekpbukq4pi467vs3gup8psmd87tlb8f5fj9xbtoy.jpg" alt="Image 4" class="img-fluid">
                        </div>
                        <div class="col-md-12">
                            <br /><br />
                            <p>These advanced technologies and control mechanisms are made possible by NALPLAST'S dedicated team of professionals in production, sales, and management, all of whom are committed to delivering utmost customer satisfaction.</p>
                            <br /><br />
                        </div>
                        <div class="col-md-6">
                            <img src="../../../../assets/AboutUsImages/AD_Challenger_Hydraulics_Cylinders_001-1024x632.jpeg" alt="Image 5" class="img-fluid">
                        </div>
                        <div class="col-md-6"><br /><br /><br /><br />
                            <p>We extend a warm welcome and express our sincere interest in providing exceptional service to our valued customers. Located conveniently in the King Salman Industrial City in Riyadh, NALPLAST is privileged to have you visit our esteemed establishment. We kindly request that you explore our facilities and offerings to experience the quality and dedication we uphold.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- CONTENT END -->
    <app-footer1></app-footer1>
</div>
<app-loader></app-loader>