<div class="comment-respond m-t30" id="respond">

    <h2 class="comment-reply-title" id="reply-title">Add a Review
        <small>
            <a style="display:none;" href="javascript:void(0);" id="cancel-comment-reply-link"
                rel="nofollow">Cancel reply</a>
        </small>
    </h2>

    <form class="comment-form" id="commentform" method="post">

        <p class="comment-form-author">
            <label>Name <span class="required">*</span></label>
            <input class="form-control" type="text" value=""
                name="user-comment" placeholder="Author" id="author">
        </p>

        <p class="comment-form-email">
            <label>Email <span class="required">*</span></label>
            <input class="form-control" type="text" value="" name="email"
                placeholder="Email">
        </p>

        <p class="comment-form-url">
            <label>Website</label>
            <input class="form-control" type="text" value="" name="url"
                placeholder="Website" id="url">
        </p>

        <p class="comment-form-comment">
            <label>Comment</label>
            <textarea class="form-control" rows="8" name="comment"
                placeholder="Comment" id="comment"></textarea>
        </p>

        <p class="form-submit">
            <button class="site-button site-btn-effect"
                type="button">Submit</button>
        </p>

    </form>

</div>