<header class="site-header header-style-1 mobile-sider-drawer-menu">
    <div class="top-bar site-bg-white">
        <div class="container">
            <div class="d-flex justify-content-between">
                <div class="wt-topbar-left d-flex flex-wrap align-content-start">
                    <ul class="wt-topbar-left-info">
                        <li><i class="flaticon-call"></i><span>Call :</span> +966 11 4655880</li>
                        <li><i class="flaticon-mail"></i><span>Email :</span> info@nalplast.com</li>
                    </ul>
                </div>
                <div class="wt-topbar-right d-flex flex-wrap align-content-center">
                    <div class="wt-topbar-right-info">
                        <ul class="social-icons">
                            <li><a href="https://www.facebook.com" class="fa fa-facebook"></a></li>
                            <li><a href="https://twitter.com" class="fa fa-twitter"></a></li>
                            <li><a href="https://in.linkedin.com" class="fa fa-linkedin"></a></li>
                            <li><a href="https://in.pinterest.com" class="fa fa-pinterest"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sticky-header main-bar-wraper  navbar-expand-lg">
        <div class="main-bar">
            <div class="container clearfix">
                <div class="logo-header">
                    <div class="logo-header-inner logo-header-one">
                        <a routerLink="/index">
                            <img src="assets/images/logo 1.png" alt="">
                        </a>
                    </div>
                </div>
                <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button"
                    class="navbar-toggler collapsed">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar icon-bar-first"></span>
                    <span class="icon-bar icon-bar-two"></span>
                    <span class="icon-bar icon-bar-three"></span>
                </button>
                <div class="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                    <ul class=" nav navbar-nav">
                        <li class="active has-child"><a routerLink="/index">Home</a></li>
                        <li class="has-child"><a routerLink="/about">About Us</a></li>
                        <li class="has-child">
                            <a href="javascript:void(0);">Products</a>
                            <div class="fa fa-angle-right submenu-toogle"></div>
                            <ul class="sub-menu">
                                <li>
                                    <a href="javascript:void(0);">Pipes</a>
                                    <div class="fa fa-angle-right submenu-toogle"></div>
                                    <ul class="sub-menu">
                                        <li>
                                            <!-- <a routerLink="/product">uPVC</a> -->
                                            <a>uPVC</a>
                                        </li>
                                        <li>
                                            <a>PVC</a>
                                        </li>
                                        <li>
                                            <a>cPVC</a>
                                        </li>
                                        <li>
                                            <!-- <a>HDPE</a> -->
                                            <a>Multi Layers “Foam Core” PVC Pipe</a>
                                        </li>
                                        <li>
                                            <!-- <a>PPR</a> -->
                                            <a>DWC (Double wall Corrugated) PE pipe</a>
                                        </li>
                                        <li>
                                            <!-- <a>CorrugatedDuct(COD)</a> -->
                                            <a>HDPE</a>
                                        </li>
                                        <li>
                                            <a>COD Pipe for telecommunication services</a>
                                        </li>
                                        <li>
                                            <a>PPR</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Fittings</a>
                                    <div class="fa fa-angle-right submenu-toogle"></div>
                                    <ul class="sub-menu">
                                        <li>
                                            <!-- <a>Pressure</a> -->
                                            <a>Non-Pressure(Orange)</a>
                                        </li>
                                        <li>
                                            <!-- <a>Non-pressure</a> -->
                                            <a>Pressure PVC</a>
                                        </li>
                                        <li>
                                            <!-- <a>HDPE</a> -->
                                            <a>cPVC</a>
                                        </li>
                                        <li>
                                            <!-- <a>PPR</a> -->
                                            <a>PVC SCH 40 </a>
                                        </li>
                                        <li>
                                            <a>DWC (Double wall Corrugated) PE pipe</a>
                                        </li>
                                        <li>
                                            <a>PPR</a>
                                        </li>
                                        <li>
                                            <a>Specers</a>
                                        </li>
                                    </ul>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0);">Solvent and cleaner</a>
                                    <div class="fa fa-angle-right submenu-toogle"></div>
                                    <ul class="sub-menu">
                                        <li>
                                            <a href="javascript:void(0);">Cement</a>
                                            <div class="fa fa-angle-right submenu-toogle"></div>
                                            <ul class="sub-menu">
                                                <li>
                                                    <a>EZ Weld cPVC Cement 786 Orange Heavy Body</a>
                                                </li>
                                                <li>
                                                    <a>EZ Weld PVC Cement 755 Grey Heavy Body</a>
                                                </li>
                                                <li>
                                                    <a>EZ Weld PVC Cement 766 Clear Regular Body</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a>EZ Weld Pipe Cleaner 733 Clear</a>
                                        </li>
                                        <li>
                                            <a>EZ Weld Pipe Primer 722 Purple</a>
                                        </li>
                                    </ul>
                                </li> -->
                            </ul>
                        </li>
                        <li><a routerLink="/resources">Resources</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/faq">Faq</a></li>
                    </ul>
                </div>
                <div class="extra-nav header-1-nav">
                    <div class="extra-cell two">
                        <div class="ap-btn-outer">
                            <a class="ap-btn contact-slide-show" [routerLink]="'/calculator'">Calculator 
                                <i class="fa fa-angle-double-right slide-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>