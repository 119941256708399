<div class="blog-post-style-2 blog-post-single bg-white p-b30">

    <div class="wt-post-media">
        <img src="{{data.image1}}" alt="">
    </div>

    <div class="wt-post-info">
        <div class="wt-post-meta ">
            <ul>
                <li class="post-date">{{data.date}}</li>
                <li class="post-author"><a routerLink="/our-team-detail">By <span>{{data.author}}</span></a> </li>
                <li class="post-comment"><a routerLink="/our-team-detail">{{data.comments}} <span>Comment</span></a> </li>                                                
            </ul>
        </div>
        <div class="wt-post-title ">
            <h3 class="post-title">{{data.title}}</h3>
        </div>

        <div class="wt-post-discription" [innerHTML]="data.description">
        </div>
    </div>

</div>

<div class="one-column m-b30">
    <div class="wt-media">
        <img src="{{data.image2}}" alt="" class="img-responsive">
    </div>
</div>