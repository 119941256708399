<div class="page-wraper">

    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->


    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-page-banner title="Contact us" subtitle="Contact us"></app-page-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- CONTACT FORM -->
        <div class="section-full  p-t120 p-b120">
            <div class="section-content">
                <div class="container">
                    <div class="contact-one">
                        <!-- CONTACT FORM-->
                        <div class="row no-gutters d-flex justify-content-center flex-wrap align-items-center">

                            <div class="col-lg-7 col-md-12">
                                <div class="contact-form-outer site-bg-gray">
                                    <form class="cons-contact-form" method="post" action="form-handler2.php">


                                        <!-- TITLE START-->
                                        <div class="section-head left wt-small-separator-outer">
                                            <h3 class="wt-title m-b30">Contact Form</h3>
                                            <p>Feel Free to Contact us any time. we will get back to you as soon as we
                                                can't</p>
                                        </div>
                                        <!-- TITLE END-->

                                        <div class="row">

                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <input name="username" type="text" required class="form-control"
                                                        placeholder="Name">
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12">
                                                <div class="form-group">
                                                    <input name="email" type="text" class="form-control" required
                                                        placeholder="Email">
                                                </div>
                                            </div>

                                            <div class="col-lg-6 col-md-12">
                                                <div class="form-group">
                                                    <input name="phone" type="text" class="form-control" required
                                                        placeholder="Phone">
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <input name="subject" type="text" class="form-control" required
                                                        placeholder="Subject">
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <textarea name="message" class="form-control" rows="4"
                                                        placeholder="Message"></textarea>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <button type="submit" class="site-button sb-bdr-dark">Submit
                                                    Now</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="contact-info site-bg-black"
                                    style="background-image: url(assets/images/background/footer-map.png);">
                                    <!-- TITLE START-->
                                    <div class="section-head left wt-small-separator-outer when-bg-dark">
                                        <h3 class="wt-title">Info</h3>
                                    </div>
                                    <!-- TITLE END-->

                                    <div class="contact-info-section">

                                        <div class="c-info-column">
                                            <span class="m-t0">Address info</span>
                                            <p>Al Faruq, Riyadh 12863, Saudi Arabia</p>
                                        </div>

                                        <div class="c-info-column">
                                            <span class="m-t0">Phone number</span>
                                            <p>+966 11 4655880</p>
                                        </div>

                                        <div class="c-info-column">
                                            <span class="m-t0">Email address</span>
                                            <p>info@nalplast.com</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- GOOGLE MAP -->
        <div class="section-full">
            <div class="container">
                <div class="gmap-outline p-b120">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d906.4412594384685!2d46.7842241!3d24.6662147!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0654580b8d7d%3A0xc0635a05cc50ccfb!2sPalladium%20Center!5e0!3m2!1sen!2sjo!4v1696247842033!5m2!1sen!2sjo"
                        width="1100" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>

    </div>
    <!-- CONTENT END -->

    <app-footer1></app-footer1>

</div>

<app-loader></app-loader>