<div class="blog-post blog-post-4-outer m-b30" *ngFor = "let blog of data">
    <div class="wt-post-media wt-img-effect zoom-slow">
        <a routerLink="{{blog.linkToReadMore}}"><img src="{{blog.image}}" alt=""></a>
    </div>
    <div class="wt-post-info">
        <div class="wt-post-meta ">
            <ul>
                <li class="post-date"><span>{{blog.day}}</span>{{blog.month}}</li>
                <li class="post-category">{{blog.field}}</li>
            </ul>
        </div>
        <div class="wt-post-title ">
            <h4 class="post-title"><a routerLink="{{blog.linkToReadMore}}">{{blog.title}}</a></h4>
        </div>
        <div class="wt-post-text ">
            <p>
                {{blog.description}}
            </p>
        </div>
        <div class="wt-post-readmore ">
            <a routerLink="{{blog.linkToReadMore}}" class="site-button-link black">Read More</a>
        </div>
    </div>
</div>