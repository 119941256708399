<div class="page-wraper">
  <app-header1></app-header1>
  <div class="page-content">
    <app-slider5></app-slider5>

    <!-- <app-about-section1 [data]="aboutUsSection"></app-about-section1> -->
    
    <app-facts2 [data]="factsSection"></app-facts2>

    <app-team1 [data]="teamSection1"></app-team1>

    <app-services1 [data]="servicesSection"></app-services1>

    <app-buildfuture1 [data]="buildFutureSection"></app-buildfuture1>

    <app-testimonial1 [data]="testimonialsSection"></app-testimonial1>

    <!-- <app-updates1 [data]="updatesSection"></app-updates1> -->

    <app-ourclients1 [data]="clientsSection"></app-ourclients1>
  </div>
  <app-footer1></app-footer1>
</div>
<app-loader></app-loader>