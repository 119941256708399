import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  faqs = [
    {
      question: "Continuously Trained On Safety Issues?",
      answer: "Will ensure you are properly introduced and integrated. you will be paired with an appropriate person for partner drills. Ask the club about our Level 0 introductory class where everyone is new, or start with Level 1, where participants will have a range of experience, from be"
    },
    {
      question: "How Can We Get Help From You?",
      answer: "Monday – Friday: 9 to 7pm Saturday: 8 to 6pm Sunday: Closed"
    },
    {
      question: "How Are Our workers Working For You?",
      answer: "You can schedule your appointment by phone, in person or online. Reservations for your appointment are held with a credit card or gift certificate."
    },
    {
      question: "Do I have to commit to a contract?",
      answer: "No special clothing is required; come as you are! If you are enjoying several services, you will be provided with a robe and slippers. Towels for the eucalyptus steam shower are available. Your personal comfort is always most important to us! Efficiently unleash cross-media information without cross-media value. Quickly timely deliverables. Efficiently unleash cross-media information without cross-media value. Quickly maximize maximized timely deliverables for real-time schemas. Dramatically maintain solutions. Dramatically maintain solutions.Efficiently unleash cross-media information without cross-media value. Quickly maximize timely fixed deliverables for real-time functional schemas."
    },
    {
      question: "Any contracts or commitments?",
      answer: "Your certificate is considered the same as cash. It is necessary to keep track of this valuable property."
    },
    {
      question: "How can I pay for my purchases?",
      answer: "Will ensure you are properly introduced and integrated. you will be paired with an appropriate person for partner drills. Ask the club about our Level 0 introductory class where everyone is new, or start with Level 1, where participants will have a range of experience, from be"
    },
    {
      question: "How much time I will spend on planning?",
      answer: "Monday – Friday: 9 to 7pm Saturday: 8 to 6pm Sunday: Closed "
    },
    {
      question: "Air conditioner is different then others? ",
      answer: "You can schedule your appointment by phone, in person or online. Reservations for your appointment are held with a credit card or gift certificate."
    },
    {
      question: "How much you charge hourly?",
      answer: "No special clothing is required; come as you are! If you are enjoying several services, you will be provided with a robe and slippers. Towels for the eucalyptus steam shower are available. Your personal comfort is always most important to us! Efficiently unleash cross-media information without cross-media value. Quickly timely deliverables. Efficiently unleash cross-media information without cross-media value. Quickly maximize maximized timely deliverables for real-time schemas. Dramatically maintain solutions. Dramatically maintain solutions.Efficiently unleash cross-media information without cross-media value. Quickly maximize timely fixed deliverables for real-time functional schemas."
    },
    {
      question: "Why Mechanical Evolution Start?",
      answer: "Your certificate is considered the same as cash. It is necessary to keep track of this valuable property."
    }
  ];

}
