<div class="project-single-sibar-info m-b30">
    <h4 class="wt-tilte">Project Information</h4>
    <p>{{data.intro}}</p>
    <ul class="info-list-1">
        <li>
            <h5 class="title">Date :</h5><span>{{data.date}}</span>
        </li>
        <li>
            <h5 class="title">Category :</h5><span>{{data.category}}</span>
        </li>
        <li>
            <h5 class="title">Clients :</h5><span>{{data.clients}}</span>
        </li>
        <li>
            <h5 class="title">Location :</h5><span>{{data.location}}</span>
        </li>
    </ul>
</div>