<div class="project-box-style1">
    <div class="project-content">
        <div class="project-title">
            {{project.title}}
        </div>
        <h4 class="project-title-large"><a routerLink="{{project.link}}">{{project.subtitle}}</a></h4>
    </div>
    <div class="project-media">
        <img src="{{project.image}}" alt="">
    </div>
    <div class="project-view">
        <a class="elem pic-long project-view-btn" href="{{project.image}}" title="{{project.title}}"
            attr.data-lcl-txt="{{project.subtitle}}" attr.data-lcl-author="{{project.author}}"
            attr.data-lcl-thumb="{{project.image}}">
            <i></i>
        </a>
    </div>
</div>