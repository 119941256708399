<div class="page-wraper">

    <!-- HEADER START -->
    <app-header5></app-header5>
    <!-- HEADER END -->

    <!-- CONTENT START -->
    <div class="page-content">

        <!-- SLIDER START -->
        <app-slider5></app-slider5>
        <!-- END REVOLUTION SLIDER -->

        <!-- ABOUT COMPANY -->
        <app-about-section6 [data]="about1"></app-about-section6>
        <!-- ABOUT COMPANY END -->

        <!-- WELCOME SECTION START -->
        <app-about-section7 [data]="about2"></app-about-section7>
        <!-- WELCOME  SECTION END -->

        <!-- SERVICES SECTION START -->
        <app-service-section1 [data]="serviceSection"></app-service-section1>
        <!-- SERVICES SECTION END -->

        <!-- SOME FACTS START -->
        <app-facts5 [data]="factsSection"></app-facts5>
        <!-- SOME FACTS END -->

        <!-- We Love START -->
        <app-buildfuture1 [data]="weLoveSection"></app-buildfuture1>
        <!-- We Love FUTURE END -->

        <!-- TESTIMONIAL SECTION START -->
        <app-testimonial5 [data]="testimonialsSection"></app-testimonial5>
        <!-- TESTIMONIAL SECTION END -->

        <!-- CLIENT LOGO SECTION START -->
        <app-ourclients1 [data]="clientsSection"></app-ourclients1>
        <!-- CLIENT LOGO  SECTION End -->

        <!-- OUR BLOG START -->
        <app-blog-section1 [data]="blogSection"></app-blog-section1>
        <!-- OUR BLOG END -->

    </div>
    <!-- CONTENT END -->

    <!-- FOOTER START -->
    <app-footer5></app-footer5>
    <!-- FOOTER END -->

</div>

<app-loader></app-loader>