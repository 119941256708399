<div class="page-wraper">
    <app-header1></app-header1>
    <app-page-banner title="Resources" subtitle="Resources"></app-page-banner>

    <div class="catalogue-container">
        <div class="catalogue-card">
            <div class="catalogue-header">
                <img src="../../../../assets/pdf_136522 (2).png" alt="PDF Icon">
                <h4>Catalogues</h4>
            </div>
            <h4>Electrical Key Products Catalogue</h4>
            <div class="catalogue-actions">
                <a href="../../../../assets/files/Flyers-EN-IPEX-Key-Electrical-Products-R.pdf" target="_blank" class="site-button sb-bdr-dark">Download</a>
            </div>
        </div>
        <div class="catalogue-card">
            <div class="catalogue-header">
                <img src="../../../../assets/pdf_136522 (2).png" alt="PDF Icon">
                <h4>Catalogues</h4>
            </div>
            <h4>Electrical Key Products Catalogue</h4>
            <div class="catalogue-actions">
                <a href="../../../../assets/files/Flyers-EN-IPEX-Key-Electrical-Products-R.pdf" target="_blank" class="site-button sb-bdr-dark">Download</a>
            </div>
        </div>
        <div class="catalogue-card">
            <div class="catalogue-header">
                <img src="../../../../assets/pdf_136522 (2).png" alt="PDF Icon">
                <h4>Catalogues</h4>
            </div>
            <h4>Electrical Key Products Catalogue</h4>
            <div class="catalogue-actions">
                <a href="../../../../assets/files/Flyers-EN-IPEX-Key-Electrical-Products-R.pdf" target="_blank" class="site-button sb-bdr-dark">Download</a>
            </div>
        </div>
    </div>


    <app-footer1></app-footer1>
</div>
<app-loader></app-loader>