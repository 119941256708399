<div class="section-full p-t120  p-b90 bg-white">
    <ng-template ngFor let-data [ngForOf]="data">
        <div class="container">
            <div class="section-head center wt-small-separator-outer">
                <div class="wt-small-separator site-text-primary">
                    <div class="sep-leaf-left"></div>
                    <div>{{data.title}}</div>
                </div>
                <h2 class="wt-title">{{data.subtitle}}</h2>
            </div>
        </div>

        <div class="container">
            <div class="section-content">
                <app-news-box class="row d-flex justify-content-center" [data]="data.updates"></app-news-box>                
            </div>
        </div>
    </ng-template>
</div>