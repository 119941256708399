<div class="project-outer2">

    <div class="project-style-2">
        <div class="project-media">
            <img src="{{project.image}}" alt="">
        </div>

        <div class="wt-info">
            <a routerLink="{{project.link}}"><i class="fa fa-link"></i></a>
            <a class="elem pic-long" href="{{project.image}}" title="{{project.title}}"
            attr.data-lcl-txt="{{project.title}}" attr.data-lcl-author="{{project.author}}"
                attr.data-lcl-thumb="{{project.image}}">
                <i class="fa fa-expand"></i>
            </a>
        </div>
    </div>

    <div class="project-content">
        <span class="project-category">Energy</span>
        <h4 class="wt-title"><a routerLink="{{project.link}}">{{project.subtitle}}</a></h4>
    </div>

</div>