<div class="page-wraper">

    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->


    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-page-banner title="Calculator" subtitle="Calculator"></app-page-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- CONTACT FORM -->
        <div class="section-full  p-t120 p-b120">
            <div class="section-content">
                <div class="container">
                    <div class="contact-one">
                        <!-- CONTACT FORM-->
                        <div class="row no-gutters d-flex justify-content-center flex-wrap align-items-center">

                            <div class="col-lg-7 col-md-12">
                                <div class="contact-form-outer site-bg-gray">
                                    <form class="cons-contact-form" [formGroup]="form">


                                        <!-- TITLE START-->
                                        <div class="section-head left wt-small-separator-outer">
                                            <h3 class="wt-title m-b30">Calculator</h3>
                                        </div>
                                        <!-- TITLE END-->

                                        <div class="row">

                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label for="productStandard">Product Standard</label>
                                                    <input name="Product" type="text" formControlName="productStandard"
                                                        required class="form-control" placeholder="Product Standard">
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label for="wetArea">Wet Areas</label>
                                                    <input type="text" class="form-control" formControlName="wetArea"
                                                        placeholder="Wet Areas">
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label for="Drainage">Drainage Points</label>
                                                    <input name="Drainage" type="text" class="form-control"
                                                        formControlName="drainage" placeholder="Drainage Points ">
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label for="Drainage">Supply Points</label>
                                                    <input name="supplypoint" type="text" class="form-control"
                                                        formControlName="supplyPoint" placeholder="Supply Points">
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label for="buildingHigh">Building High</label>
                                                    <input name="buildinghigh" type="text" class="form-control"
                                                        formControlName="buildingHigh" placeholder="Building High">
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                    <label for="unitLevel">Unit Level</label>
                                                    <input name="unit" type="text" class="form-control"
                                                        formControlName="unitLevel" placeholder="Unit Level">
                                                </div>
                                            </div>


                                            <div class="col-md-12">
                                                <button type="submit" class="site-button sb-bdr-dark"
                                                    (click)="submitCalculator()">Calculate</button>
                                            </div>

                                            <div class="col-md-12 mt-4">
                                                <h3 class="wt-title m-b30" *ngIf="this.results > 0">Estimated Price is
                                                    {{this.results}} SAR</h3>
                                                <button type="submit" class="site-button sb-bdr-dark"
                                                    (click)="submitRFP()" *ngIf="this.results > 0">Need Help?</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- CONTENT END -->

    <app-footer1></app-footer1>

</div>

<app-loader></app-loader>