<!-- FOOTER START -->
<footer class="site-footer footer-dark">

    <!-- FOOTER BLOCKES START -->
    <div class="footer-top bg-bottom-center bg-no-repeat"
        style="background-image:url(assets/images/background/footer-map.png);">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">

                    <div class="widget widget_about">
                        <h3 class="widget-title">About Us</h3>
                        <p>We are the leaders in the building
                            construction and factories. We’re around
                            over the worldwide. We never give up
                            on the challenges</p>
                        <ul class="social-icons">
                            <li><a href="https://www.facebook.com" class="fa fa-facebook"></a></li>
                            <li><a href="https://twitter.com" class="fa fa-twitter"></a></li>
                            <li><a href="https://in.linkedin.com" class="fa fa-linkedin"></a></li>
                            <li><a href="https://in.pinterest.com" class="fa fa-pinterest"></a></li>
                        </ul>
                    </div>

                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_services">
                        <h3 class="widget-title">Services</h3>
                        <ul>
                            <li><a routerLink="/service-detail">Construction Engineering</a></li>
                            <li><a routerLink="/service-detail">Oil & Gas Refinery</a></li>
                            <li><a routerLink="/service-detail">Mechanical Engineering</a></li>
                            <li><a routerLink="/service-detail">Chemical Research</a></li>
                            <li><a routerLink="/service-detail">Civil Engineering </a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_services">
                        <h3 class="widget-title">Company Links</h3>
                        <ul>
                            <li><a routerLink="/about1">About</a></li>
                            <li><a routerLink="/project1">Projects</a></li>
                            <li><a routerLink="/blog-grid">Blog</a></li>
                            <li><a routerLink="/faq">FAQ</a></li>
                            <li><a routerLink="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>



                <div class="col-lg-3 col-md-6">
                    <div class="widget widget_newsletter">
                        <h3 class="widget-title">Newsletter</h3>
                        <p>Our newsletters contain useful blog
                            posts, case studies, “how to”s, and ways
                            to help you grow your business. </p>
                        <div class="newsletter-bx">
                            <form role="search" method="post">
                                <div class="input-group">
                                    <input name="news-letter" class="form-control" placeholder="Enter Email Address"
                                        type="text">
                                    <span class="input-group-btn">
                                        <button type="submit" class="site-button"><i
                                                class="fa fa-paper-plane"></i></button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            <div class="footer_blocks">
                <div class="row justify-content-center no-gutters">

                    <!--Block 1-->
                    <div class="col-lg-4 col-md-4">
                        <div class="block-content">
                            <div class="wt-icon-box-wraper left">
                                <div class="wt-icon-box-sm">
                                    <span class="icon-cell  site-text-primary"><i class="flaticon-call"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h4 class="wt-tilte">Call</h4>
                                    <p>+966 11 4655880</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Block 2-->
                    <div class="col-lg-4 col-md-4">
                        <div class="block-content">
                            <div class="wt-icon-box-wraper left">
                                <div class="wt-icon-box-sm">
                                    <span class="icon-cell  site-text-primary"><i class="flaticon-email"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h4 class="wt-tilte">Email</h4>
                                    <p>info@nalplast.com</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Block 3-->
                    <div class="col-lg-4 col-md-4">
                        <div class="block-content">
                            <div class="wt-icon-box-wraper left">
                                <div class="wt-icon-box-sm">
                                    <span class="icon-cell site-text-primary"><i class="flaticon-location"></i></span>
                                </div>
                                <div class="icon-content">
                                    <h4 class="wt-tilte">Address</h4>
                                    <p>Al Faruq, Riyadh 12863, Saudi Arabia</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->

    <div class="footer-bottom">
        <div class="container">
            <div class="footer-bottom-info">
                <div class="footer-copy-right">
                    <span class="copyrights-text">Copyright © 2024 by <span class="site-text-primary">NALPLAST</span> |
                        All rights reserved </span>
                </div>
            </div>
        </div>
    </div>

</footer>
<!-- FOOTER END -->