<div class="section-full p-t0  p-b10 site-bg-white" style="background-image: url(assets/images/background/bg-dott.png);">
    <div class="container">
        <ng-template ngFor let-data [ngForOf]="data">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-4 col-md-6 m-b30" *ngFor="let item of data.features">
                    <div class="service-icon-box-two text-center {{item.background}}">
                        <div class="wt-icon-box-wraper">
                            <div class="icon-xl inline-icon">
                                <span class="icon-cell site-text-primary"><i class="{{item.icon}}"></i></span>
                            </div>
                        </div>
                        <div class="service-icon-box-title">
                            <h4 class="wt-title"><a routerLink="">{{item.title}}</a></h4>
                        </div>
                        <div class="service-icon-box-content">
                            <p>{{item.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>