<div class="widget search-bx">

    <div class="text-left m-b30">
        <h4 class="widget-title">Search</h4>
    </div>
    <form role="search" method="post">
        <div class="input-group">
            <input name="news-letter" type="text" class="form-control"
                placeholder="Write your text">
            <span class="input-group-btn">
                <button type="submit" class="btn"><i class="fa fa-search"></i></button>
            </span>
        </div>
    </form>

</div>