import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare function showLoading(): any;
declare function hideLoading(): any;

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})


export class CalculatorComponent implements OnInit {
  public form = new FormGroup({
    productStandard: new FormControl('', Validators.required),
    wetArea: new FormControl(''),
    drainage: new FormControl(''),
    supplyPoint: new FormControl(''),
    buildingHigh: new FormControl(''),
    unitLevel: new FormControl('')
  });

  constructor() {

  }

  ngOnInit(): void {

  }
  results = 0;
  submitCalculator() {
    const formValue = this.form.value;
    console.log(this.form.value);
    showLoading();

    this.results = (formValue.unitLevel * 3) * (formValue.wetArea * formValue.drainage);

    setTimeout(() => {
      hideLoading()
    }, 300)
  }

  submitRFP() {
    const formValue = this.form.value;
    console.log(this.form.value);
    showLoading();


    setTimeout(() => {
      hideLoading()
    }, 500)
  }
}
