<div class="project-single-side-btn m-b30">
    <h4 class="wt-title m-b20">Brochures</h4>
    <p>View our 2024 financial prospectus brochure for an easy to read guide on all of the
        services offer.</p>


    <div class="wt-icon-box-wraper left ">
        <a href="javascript:void(0);" class="btn-block  m-tb5">
            <span class="text-black m-r10"><i class="fa fa-file-pdf-o"></i></span>
            <strong class="text-black">Download .PDF</strong>
        </a>
    </div>
    <div class="wt-icon-box-wraper left">
        <a href="javascript:void(0);" class="btn-block  m-tb5">
            <span class="text-black m-r10"><i class="fa fa-file-word-o"></i></span>
            <strong class="text-black"> Download .DOC</strong>
        </a>
    </div>

</div>