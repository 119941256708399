<div class="page-wraper">

    <!-- HEADER START -->
    <app-header1></app-header1>
    <!-- HEADER END -->


    <!-- CONTENT START -->
    <div class="page-content">

        <!-- INNER PAGE BANNER -->
        <app-page-banner title="FAQ" subtitle="FAQ"></app-page-banner>
        <!-- INNER PAGE BANNER END -->

        <!-- FAQ SECTION START -->
        <app-faqs [faqs]="faqs"></app-faqs>
        <!-- FAQ SECTION END -->

        <!-- FORM SECTION START -->
        <app-faq-form></app-faq-form>
        <!-- FORM ONE SECTION END -->


    </div>
    <!-- CONTENT END -->

    <app-footer1></app-footer1>

</div>

<app-loader></app-loader>